<template>
    <div class="btn_all_providers" @click="getAllDataProviders">
        <div class="btn_all_providers_title">All providers</div>
        <div class="btn_all_providers_icon">
            <img src="@/images/sprites/svg/arrow-down.svg" class="btn_all_providers_arrow"/>
        </div>
    </div>
</template>
<script>

export default {
  name: 'ProvidersGetButtonComponent',
  data() {
    return {}
  },
  props: {
    getAllDataProviders: {
        type: Function,
        required: true,
    },
  },
};
</script>
<style scoped lang="scss">
  .btn_all_providers {
    position: relative;
    display: flex;
    align-content: center;
    justify-content: space-between;
    border-radius: 5px;
    font-weight: 600;
    border: 1px solid rgba(117, 117, 117, 0.5);
    padding: 5px 10px;
    margin-right: 10px;
    cursor: pointer;
    &:hover {
      border: 1px solid rgba(117, 117, 117);
    }
    .btn_all_providers_icon {
      display: flex;
      justify-content: center;
      align-content: center;
      .btn_all_providers_arrow {
        transform: rotate(270deg);
      }
    }
  }
</style>
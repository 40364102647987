import axios from "axios";
import store from "@/store/index";
import constants from "@/constants/constants";
import { getAllUrl } from '@/services/config/env.js';

axios.defaults.baseURL = getAllUrl();

export default async function apiRequest(config) {
    const refreshToken = JSON.parse(localStorage.getItem(constants.LOCAL_STORAGE_NAME_USER));
    if(!refreshToken) return;

    store.commit('SET_IS_BAD_REQUEST', false);
    try {
        const response = await axios(config);
        return response;
    } catch(error) {
        if(error.response && error.response.status === constants.STATUS_CODE.UNAUTHORIZED) {
            console.log('refreshing')
            await store.dispatch('REFRESH_ACCESS_TOKEN', refreshToken);
            config.headers['Authorization'] = `Bearer ${store.getters.ACCESS_TOKEN}`;
            const retryResponse = await axios(config);
            return retryResponse;
        }
        throw error;
    }
}

<template>
    <div class="selected_additionally_info" ref="info">
        <div class="selected_additionally_list" @click="openTagsList">
            <SelectedItemTagTitle :providerName="imageData.provider?.name" :gameName="imageData.game_name?.name"/>
            <div class="selected_additionally_arrow">
                <img src="@/images/sprites/svg/arrow-down.svg" class="arrow_image-down" v-if="!isOpenTagsList">
              <img src="@/images/sprites/svg/arrow-down.svg" class="arrow_image-up" v-else>
            </div>
        </div>
        <ul class="selected_items_tags" v-if="isOpenTagsList" id="window_selected_element_tags">
            <SelectedItemTag v-for="tagElement in getFilters()" :tagElement="tagElement"/>
            <li class="selected_item__tag-admin">
                Admin ID - {{ imageData.admin_id }}
            </li>
            <SelectedItemCommonInfo :additionalTags="imageData.additional_tags"/>
        </ul>
    </div>
</template>

<script>
import constants from '@/constants/constants';
import SelectedItemTagTitle from './SelectedItemTagTitle/SelectedItemTagTitle.vue';
import SelectedItemCommonInfo from './SelectedItemCommonInfo/SelectedItemCommonInfo.vue';
import SelectedItemTag from './SelectedItemTag/SelectedItemTag.vue';

export default {
    name: "SelectedItemsTags",
    data() {
        return {
            isOpenTagsList: false,
        }
    },
    components: {
        SelectedItemTagTitle,
        SelectedItemCommonInfo,
        SelectedItemTag,
    },
    props: {
        imageData: {
            type: Object,
            required: true,
        }
    },
    methods: {
        openTagsList() {
            let upsizeVal = constants.UPSIZING_VALUE;
            this.isOpenTagsList = !this.isOpenTagsList;

            const asideBlock = document.getElementById('aside_directory_block');
            const selectBlock = document.getElementById('selected_image_container_id');
            const descriptionBlock = selectBlock.getElementsByClassName('selected_image_container_description');
            descriptionBlock[0].classList.toggle('update_description_container');

            if(selectBlock.offsetHeight > constants.MIN_HEIGHT_SELECTED_BLOCK + constants.UPSIZING_VALUE) {
                upsizeVal = 0;
            }

            asideBlock.style.gridTemplateRows = `${selectBlock.offsetHeight + (this.isOpenTagsList ? upsizeVal : -upsizeVal)}px 1fr`;
        },
        getFilters() {
            const arrTags = this.imageData.tags;
            arrTags.forEach(tag => {
                tag.type = constants.TAG_ELEMENT;
            });
            const elemProvider = {
                provider: true,
                id: this.imageData.provider.id,
                name: this.imageData.provider.name,
            };
            const objectGame = this.imageData.game_name;
            objectGame.type = constants.GAMES;
            const arrFilters = this.imageData.filter_items;
            const arr = [elemProvider, objectGame, ...arrFilters, ...arrTags ];
            return arr;
        },
    },
    beforeUnmount() {
        if(this.isOpenTagsList) this.openTagsList();
    },
    mounted() {
        this.openTagsList();
    }
}
</script>

<style lang="scss">
    .selected_additionally_info {
        width: 100%;
        padding-right: 8px;
        .selected_additionally_list {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 6px 0;
            cursor: pointer;
            .selected_additionally_arrow {
                img {
                    display: block;
                }
                .arrow_image-up {
                  transform: scaleY(-1);
                }
            }
            &:hover {
                .selected_additionally_arrow {
                    img {
                        filter: brightness(0) saturate(100%) invert(86%) sepia(35%) saturate(6545%) hue-rotate(187deg) brightness(100%) contrast(104%);
                    }
                }
            }
        }
        .selected_items_tags {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            justify-content: flex-start;
            padding-top: 10px;
            margin-right: 5px;
            gap: 8px;
            height: 100%;
            list-style: none;
           //max-height: 220px;
            overflow-y: auto;
            .selected_item__tag-admin {
                display: inline-flex;
                align-items: center;
                justify-content: center;
                background-color: #ffffff;
                border-radius: 20px;
                border: 1px solid #757575;
                padding: 8px 14px;
                list-style: none;
                margin: 0;
                box-sizing: border-box;
                font-weight: 500;
            }
        }
    }
        
</style>

<template>
  <div class="main_directory" id="main_view_element">
    <FilterPanelComponent v-if="filterPanel.isOpen" :isOpenFilterPanel="isOpenFilterPanel" :filterPanel="filterPanel" id="filter_view_element"/>
    <div class="main_view">
      <div class="search_filter_panel">
        <TogglePanelFilter v-if="!filterPanel.isOpen" @click="isOpenFilterPanel" :filterPanel="filterPanel"/>
        <HeaderPanelComponent/>
      </div>
      <MainFilterPanelComponent
        :loadingTime="loadingTime"
        :isBadRequest="isBadRequest"/>
      <ImagesViewComponent 
        :dataImages="getDataImages"
        :loadingTime="loadingTime"
        :isBadRequest="isBadRequest"/>
      <HorizontalResizeLine :filterPanelOpen="filterPanel.isOpen"/>
    </div>
  </div>
</template>

<script>
import HeaderPanelComponent from './HeaderPanelComponent/HeaderPanelComponent.vue';
import MainFilterPanelComponent from './MainFilterPanelComponent/MainFilterPanelComponent.vue';
import ImagesViewComponent from './MainImagesComponents/ImagesViewComponent.vue';
import FilterPanelComponent from './FilterPanelComponent/FilterPanelComponent.vue'
import TogglePanelFilter from './FilterPanelComponent/TogglePanelFilter/TogglePanelFilter.vue';
import HorizontalResizeLine from '@/utils/resizer/HorizontalResizeLine.vue';

export default {
  name: 'MainDirectory',
  data() {
    return {
      filterPanel: {
        isOpen: true,
      },
      dataImages: [],
      loadingTime: this.$store.getters.LOADING_TIME,
      isBadRequest: this.$store.getters.IS_BAD_REQUEST,
    }
  },
  components: {
    HeaderPanelComponent,
    MainFilterPanelComponent,
    ImagesViewComponent,
    FilterPanelComponent,
    TogglePanelFilter,
    HorizontalResizeLine,
  },
  methods: {
    isOpenFilterPanel() {
      this.filterPanel.isOpen = !this.filterPanel.isOpen;
    },
  },
  computed: {
    getDataImages() {
      this.loadingTime = this.$store.getters.LOADING_TIME;
      this.isBadRequest = this.$store.getters.IS_BAD_REQUEST;
      this.dataImages = this.$store.getters.DATA_RESPONSE;
      // console.log(this.dataImages, 'main directory')
      return this.dataImages;
    }
  },
  mounted() {
    // console.log('main URL', decodeSaveType);
  }
};
</script>

<style scoped lang="scss">
  .main_directory {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    .main_view {
      display: flex;
      flex-direction: column;
      position: relative;
      flex-basis: auto;
      width: 100%;
      .search_filter_panel {
        display: flex;
        align-content: flex-start;
        justify-content: flex-start;
        gap: 10px;
        padding-left: 20px;
        padding-bottom: 10px;
      }
    }
  }

</style>

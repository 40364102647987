<template>
    <div class="vertical_resize_line" @mousedown="mouseDown">
        <div class="vertical_resize_line-icon">
            <!-- <img src="@/images/sprites/png/resize-icon.png"> -->
        </div>
    </div>
</template>

<script>
import constants from '@/constants/constants';

export default {
  name: 'VerticalResizeLine',
  data() {
    return {
      isMouseDown: false,
      positionY: 0,
      resizeMainBlock: null,
      resizeAsideBlock: null,
      resizeFilterBlock: null,
      asideBlock: null,
      shiftMainBlock: null,
      minHeighSelect: constants.MIN_HEIGHT_SELECT_DISPLAY,
    }
  },
  components: {
  },
  props: {
    isVerticalResizeEnable: {
        type: Object,
        required: false,
    },
  },
  methods: {
    mouseDown(event) {
      event.preventDefault();
      this.isMouseDown = true;
      this.positionY = event.screenY;
      this.isVerticalResizeEnable.value = true;
      
      const windowTags = document.getElementById('window_selected_element_tags');
      this.minHeighSelect = !!windowTags ? constants.MIN_HEIGHT_WITH_OPEN_WINDOW : constants.MIN_HEIGHT_SELECT_DISPLAY;
    },
    mouseUp() {
      this.isMouseDown = false;
      this.isVerticalResizeEnable.value = false;
    },
    resizeComponents(event) {
      if(this.isMouseDown && !!this.selectBlock) {
        let moveDistance = event.screenY - this.positionY;
        let heightElement = this.selectBlock.offsetHeight + moveDistance;

        let heightCollectElement = this.collectBlock.offsetHeight - moveDistance;

        if(heightCollectElement <= constants.MIN_HEIGHT_COLLECT_DISPLAY) {
          heightElement = this.selectBlock.offsetHeight;
        } else if(heightElement <= this.minHeighSelect) {
          heightElement = this.minHeighSelect;
          heightCollectElement = this.collectBlock.offsetHeight;
        }
      // console.log(heightElement)
        this.asideBlock.style.gridTemplateRows = `${heightElement}px 1fr`;
        this.positionY = event.screenY;
      }
    },

  },
  mounted() {
    this.selectBlock = document.getElementById('selected_image_container_id');
    this.collectBlock = document.getElementById('collect_images_component_id');
    this.asideBlock = document.getElementById('aside_directory_block');

    window.addEventListener('mouseup', (e) => {
      this.mouseUp(e);
    });
    window.addEventListener('mousemove', (e) => {
      if(!this.isMouseDown) return;
      this.resizeComponents(e)
    });
  },
  watch: {
    // filterPanelOpen(newValue) {
    //   this.resizeFilterBlock = document.getElementById('filter_view_element');

    //   const widthFilterBlock = newValue ? this.resizeFilterBlock.offsetWidth : 0;

    //   const arrTemplate = this.appBlock.style.gridTemplateColumns.split(' ');
    //   const mainWidth = this.resizeMainBlock.offsetWidth;
    //   const mainGoBeyond = mainWidth - widthFilterBlock - MIN_WIDTH;

    //   if(arrTemplate[2] === '1fr') {

    //     const shiftWidth = mainGoBeyond >= 0 ? 10 : widthFilterBlock;

    //     this.appBlock.style.gridTemplateColumns = `48px ${mainWidth + shiftWidth + (newValue ? 0 : 0)}px 1fr`;

    //     this.shiftMainBlock = null;

    //   } else if(mainGoBeyond < 0) {

    //     const newAsideWidth = parseInt(arrTemplate[2]) + mainGoBeyond + 10;

    //     this.appBlock.style.gridTemplateColumns = `48px 1fr ${newAsideWidth}px`;

    //     this.shiftMainBlock = mainGoBeyond;

    //   } else if (this.shiftMainBlock){

    //     const newAsideWidth = parseInt(arrTemplate[2]) - this.shiftMainBlock - 10;
        
    //     this.appBlock.style.gridTemplateColumns = `48px 1fr ${newAsideWidth}px`;

    //     this.shiftMainBlock = null;

    //   }
    // }
  }
};
</script>

<style lang="scss">
  .vertical_resize_line {
    position: absolute;
    bottom: -8px;
    z-index: 2;
    width: 100%;
    height: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    cursor: row-resize;
    .vertical_resize_line-icon {
      position: relative;
      left: 50%;
      width: 24px;
      height: 24px;
      visibility: hidden;
      transform: rotate(90deg);
      img {
        width: 100%;
      }
    }
    &:hover {
      img {
        visibility: visible;
      }
    }

  }
</style>
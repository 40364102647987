<template>
        <div class="selected_items__info">
            <div class="selected_item__info-data">
                <div class="selected_item__info selected_item__info-rating">
                    <img src="@/images/icons/quality.svg" alt="Rating"/>
                </div>
                <div class="selected_item__info">
                    {{ getExtensionFile() }}
                </div>
                <div class="selected_item__info">
                    {{ infoData.resolution}}
                </div>
                <!-- <div class="selected_item__info">
                    {{infoData.size}} KB
                </div> -->
            </div>
        </div>
</template>

<script>

export default {
    name: "SelectedItemsInfo",
    data() {
        return {
            element: null,
        }
    },
    props: {
        infoData: {
            type: Object,
            required: true,
        }
    },
    methods: {
        getExtensionFile() {
            const extension = this.infoData.name.match(/\.([^.]+)$|$/)[1];
            return extension?.toUpperCase();
        }
    }
}
</script>

<style scoped lang="scss">
    .selected_items__info {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        align-content: center;
        height: 30px;
        gap: 5px;
        font-weight: 600;
        .selected_item__info-rating {
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                display: block;
                width: 20px;
                height: 20px;
            }
        }
        .selected_item__info-data {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;

        }
    }
</style>

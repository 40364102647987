<template>
    <li class="asset_type_panel_window_item">
        <div class="asset_type_panel_main_type" @click="activatedAssetType">
            <div :class="mainAssetTypeId === assetType.id ? 'window_item-title active' : 'window_item-title'">
                <img src="@/images/icons/icon_asset_type.svg" alt="asset_icon"/> 
                <span>{{ assetType.name }}</span>
            </div>
            <div class="window_item-enable_icon" v-if="mainAssetTypeId === assetType.id">
                <img src="@/images/icons/icon_asset_enable.svg" alt="enable_icon"/>
            </div>
        </div>
        <ul class="asset_type_panel_subasset_list" v-if="mainAssetTypeId === assetType.id">
            <SubAssetItemComponent v-for="subAsset in getAssetSubtypesFilter(assetType.asset_type_filter_id)" 
                :subAsset="subAsset"
                :assetType="assetType"/>
        </ul> 
    </li>
</template>

<script>
import SubAssetItemComponent from './SubAssetItemComponent/SubAssetItemComponent.vue';
import convertURLtoObject from '@/utils/convertURLtoObject/convertURLtoObject';
import { addRoutingDataToActiveStorage } from '@/utils/addDataToActiveStorage/addDataToActiveStorage';

export default {
    name: 'AssetTypeItemComponent',
    data() {
        return {
        }
    },
    components: {
        SubAssetItemComponent,
    },
    props: {
        assetType: {
            type: Object,
            required: true,
        },
        mainAssetTypeId: {
            type: Number,
            required: true,
        },
        mainAssetTypeName: {
            type: String,
            required: true,
        },
    },
    methods: {
        removeActiveFilters() {
            this.$store.commit('REMOVE_ALL_ACTIVE_FILTERS', '');
            this.$store.commit('REMOVE_SEARCH_TEXT', '');
        },
        getNewData() {
            this.$store.dispatch('DEBOUNCED_GET_DATA', '');
            this.$store.dispatch('GET_DATA_SMART_FILTERS', '');
        },
        getNameStorage(name, id) {
            return name.toLowerCase() + '-' + id;
        },
        saveURLForSession(currentURL) {
            sessionStorage.setItem(this.getNameStorage(this.mainAssetTypeName, this.mainAssetTypeId), JSON.stringify(currentURL));
        },
        updateStore(namePathStore, data) {
            this.$store.commit(namePathStore, data);
        },
        activatedAssetType() {
            if(this.mainAssetTypeId !== this.assetType.id) {
                let currentQuery = { ...this.$route.query }; //изменение URL ассета
                this.removeActiveFilters();
                this.$store.commit('CHANGE_ACTIVE_MAIN_ASSET', this.assetType); //тут происходит изменение главного ассета

                this.$store.dispatch('GET_DATA_FILTERS', '').then((e) => {
                    addRoutingDataToActiveStorage(saveFilters, this.updateStore, true);
                    this.getNewData();
                });
            }
            
        },
        getAssetSubtypesFilter(assetTypeFilterId) {
            const subFilters = this.$store.getters.PARENT_FILTERS_BY_ID(assetTypeFilterId); //здесь получаем список подтипов ассета
            // console.log(subFilters)
            return subFilters?.filter_items || [];
        }
    },
}

</script>

<style scoped lang="scss">
.asset_type_panel_window_item {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:not(:first-child) .asset_type_panel_main_type{
        border-top: 1px solid #EBEFF5;
    }
    &:first-child .asset_type_panel_main_type{
        border-radius:  10px 10px 0 0;
    }
    
    &:last-child .asset_type_panel_subasset_item:last-child{
        border-radius: 0 0 10px 10px;
    }
    .asset_type_panel_main_type {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 16px;
        cursor: pointer;
        
        &:hover {
            background-color: #EBEFF5;
        }
        .window_item-title {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 6px;
            img {
                width: 16px;
                height: 16px;
            }
        }
        .window_item-enable_icon {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .active {
            color: #0085FF;
            img {
                filter: brightness(0) saturate(100%) invert(34%) sepia(97%) saturate(2994%) hue-rotate(196deg) brightness(105%) contrast(103%);
            }
        }
    }
    .asset_type_panel_subasset_list {
        list-style: none;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        border-top: 1px solid #EBEFF5;
    }
}
</style>
import constants from '@/constants/constants';

const getLinkSearchRequest = (text) => !!text ? '&search_string=' + text : '';

const getPathLink = (type) => {
    let path = '';
    switch(type) {
        case constants.PROVIDERS: path = '&provider=';
            break;
        case constants.GAMES: path = '&game_name=';
            break;
        case constants.FILTERS: path = '&filter_items=';
            break;
        case constants.TAGS: path = '&tags_id=';
            break;
        default: break;
    }
    return path;
}

const getLinkFromFiltersData = (data, type) => {
    let link = '';
    let path = getPathLink(type);

    for (let i = 0; i <= data.length-1; i++) {
        if(type === constants.TAGS || type === constants.FILTERS) {
            if(data[i].value) {
              link += `${path}${data[i].id}`;
            }
        } else {
            link += `${path}${data[i].id}`;
        }
    }

    return link;
}

export function createLinkForRequest(numberPage, {activeAsset, searchText, activeProviders, activeGames, activeFilters, activeTags}) {
    const arrayData = [[activeProviders, constants.PROVIDERS], [activeGames, constants.GAMES], [activeFilters, constants.FILTERS], [activeTags, constants.TAGS]];

    let linkRequest = arrayData.reduce((res, data) => {
        res += getLinkFromFiltersData(data[0], data[1]);
        return res;
    }, '');

    const link = `/file_storage/?page=${numberPage}&page_size=${constants.SIZE_PAGE}${getLinkSearchRequest(searchText)}&asset_type_id=${activeAsset.id}&is_hq=false&is_upscaled=false${linkRequest}`;

    return link;
}
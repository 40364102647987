<template>
    <div class="custom_radio_container">
        <label class="custom_radio">
            <input type="radio" ref="input_radio" 
                :name="radioInfo.block_name" 
                :checked="radioInfo.checked" 
                :value="radioInfo.name"
                v-on:change="radioChangeFunction">
            <div class="custom_radio_title">
                {{ radioInfo.name }}
            </div>
        </label>
    </div>
</template>

<script>
export default {
    name: 'CustomRadioComponent',
    data() {
        return {
        }
    },
    props: {
        radioInfo: {
            type: Object,
            required: true,
        },
        radioChangeFunction: {
            type: Function,
            required: true,
        },
    },
};
</script>

<style scoped lang="scss">
    .custom_radio_container {
        .custom_radio {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 6px;
            width: 100%;
            font-weight: 500;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            cursor: pointer;
            .custom_radio_title {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                padding-bottom: 1px;
            }
            input[type="radio"] {
                -webkit-appearance: none;
                appearance: none;
                background-color: #ffffff;
                width: 16px;
                height: 16px;
                border: 2px solid #cccccc;
                border-radius: 50%;
                transform: translateY(-0.075em);
                cursor: pointer;
                display: grid;
                place-content: center;
                &:hover {
                    border: 2px solid #111111;
                }
                &:checked {
                    border: 2px solid #2196F3;
                }
                &::before {
                    content: "";
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    transform: scale(0);
                    transition: 120ms transform ease-in-out;
                    background-color: #2196F3;
                    box-sizing: border-box;
                  }
                &:checked::before {
                    transform: scale(1);
                  }
            }
        }
    }
</style>


<template>
    <div class="collect__images__nav">
        <button class="collect__images__btn collect__images__btn-clear" @click="clickToClear">
            <img src="@/images/sprites/svg/clear.svg" alt="">
        </button>
        <div class="collect__images__btns">
            <button class="collect__images__btn collect__images__btn-add">
                <img src="@/images/sprites/svg/folder2.svg" alt="">
            </button>
            <button class="collect__images__btn collect__images__btn-load" @click="downloadFiles">
                <img src="@/images/sprites/svg/download.svg" alt=""> zip
            </button>
        </div>
        <ClearAllPopup v-show="isOpenPopUp" :clearAllCollect="clearAllCollect" :closePoUp="closePoUp"/>
    </div>
</template>

<script>
import ClearAllPopup from '@/components/PopUpComponents/ClearPopUp/ClearAllPopup.vue';
import constants from '@/constants/constants';
import { getAllUrl } from '@/services/config/env.js';

export default {
    name: "CollectNavigationButtons",
    data() {
        return {
            isOpenPopUp: false,
        }
    },
    components: {
        ClearAllPopup
    },
    methods: {
        clickToClear() {
            const arrCollect = this.$store.getters.DATA_COLLECT;
            if(!arrCollect.length) return;
            if(arrCollect.length > constants.IS_VIEW_POPUP_CLEAR_FILES_NUMBER) {
                this.isOpenPopUp = true;
            } else {
                this.clearAllCollect();
            }

        },
        clearAllCollect() {
            this.isOpenPopUp = false;
            this.$store.commit('CLEAR_DATA_COLLECT', [])
        },
        closePoUp() {
            this.isOpenPopUp = false;
        },
        downloadCollectImages() {
            const arrCollect = this.$store.getters.DATA_COLLECT;

            if(!arrCollect.length) return;

            let link = `${getAllUrl()}/file_storage/download/?`;

            arrCollect.forEach((elem, i) => {
                if(i === 0) {
                    link = link + 'files=' + (elem.oldId ? elem.oldId : elem.id);
                } else {
                    link = link + '&files=' + (elem.oldId ? elem.oldId : elem.id);
                }
            });

            let linkElem = document.createElement('a');
            linkElem.setAttribute('href', link);
            linkElem.setAttribute('download','download');
            onload=linkElem.click();
        },
        downloadFiles() {
            this.$store.dispatch('DOWNLOAD_FILES').then((res) =>{
                if(res.status === constants.STATUS_CODE.OK) {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const linkElement = document.createElement('a');
                    linkElement.href = url;
                    linkElement.setAttribute('download', 'archiveFiles_GameArtKit.zip');
                    linkElement.click();
                    window.URL.revokeObjectURL(url);
                }
            }).catch((err) => {
                console.error(`${err.name}:${err.message} An error occurred while uploading the file`);
            })
        },
    }
}
</script>

<style scoped lang="scss">
    .collect__images__nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .collect__images__btn {
            height: 50px;
            min-width: 50px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: none;
            border-radius: 10px;
            background-color: #fff;
            font-weight: 600;
            font-size: 15px;
            padding: 0 20px;
            cursor: pointer;
            text-decoration: none;
        }
        .collect__images__btn-clear:hover {
            img {
                filter: brightness(0) saturate(100%) invert(37%) sepia(38%) saturate(6479%) hue-rotate(343deg) brightness(98%) contrast(106%);
            }
        }
        .collect__images__btns {
            display: flex;
            gap: 10px;
            .collect__images__btn:hover {
                color: #7cb2ff;
                img {
                    filter: brightness(0) saturate(100%) invert(86%) sepia(35%) saturate(6545%) hue-rotate(187deg) brightness(100%) contrast(104%);
                }
            }
            .collect__images__btn-load {
                display: inline-flex;
                gap: 5px;
                align-content: center;
                justify-content: center;
                align-items: center;
                img {
                    display: block;
                }
            }
        }
    }
</style>
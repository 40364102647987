<template>
  <StartPageCompontent>
    <AuthorizationPanelLogIn/>
  </StartPageCompontent>
</template>

<script>
import StartPageCompontent from '@/components/Pages/LoginPages/StartPageCompontent/StartPageCompontent.vue';
import AuthorizationPanelLogIn from '@/components/Pages/LoginPages/AuthorizationPageComponents/AuthorizationPanelLogIn/AuthorizationPanelLogIn.vue';

export default {
  name: 'AuthorizationView',
  components: {
    StartPageCompontent,
    AuthorizationPanelLogIn,
  },
};
</script>

import constants from "@/constants/constants";

const replaceWord = function(word, sub, isSplit=false, splitSub) {
    const repWord = word.replace(/\[|\]/g, sub).trim();
    return isSplit ? repWord.split(splitSub) : repWord;
}
const capitalizeText = function(text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
}

const formatTextForID = function(text, id) {
    return text.toLowerCase().replace(/\s/g,'_') + '-' + id;
}

export const encodeElementForURL = function(data, type) {
    let url = '';
    if(type === constants.ASSETS || type === constants.FILTERS) {
        url = `${data.name.toLowerCase()}[${data.parent_id || data.id}]`;
    }
    if(type === constants.GAMES) {
        url = `{${data.parent_id}}`;
    }
    if(type !== constants.ASSETS && type !== constants.SEARCH_REQUEST) {
        url += `[${data.activeElementName.toLowerCase()}]=${data.id}`;
    }
    if(type === constants.SEARCH_REQUEST) {
        url = `[${data.toLowerCase()}]`;
    }
    
    return encodeURI(url);
};

export const decodeElementFromURL = function(data, type) {
    try {
        let url = decodeURI(data);
        const result = {};
    
        if(type === constants.ASSETS) {
            const [name, id] = replaceWord(url, ' ', true, ' ');
            result.name = capitalizeText(name);
            result.id = +id;
    
        } else if (type === constants.PROVIDERS || type === constants.GAMES || type === constants.TAGS) {
            let [name, id] = replaceWord(url, '', true, '=');
            let parentId = null;
    
            if(type === constants.GAMES) {
                const parentIDName = name.replace(/{|}/gi, ' ').trim().split(' ');
                name = parentIDName.slice(1,parentIDName.length).join(' ');
                parentId = parentIDName[0];
            }
    
            result.name = type;
            result.parent_id = +parentId;
            result.value = formatTextForID(name, id);
            result.activeElementName = name.split(' ').map(word => capitalizeText(word)).join(' ');
            result.id = +id;
    
        } else if (type === constants.FILTERS) {
            const [name, parentId, elementName, id] = replaceWord(url, '=', true, '=').filter((data) => data);
    
            result.name = name;
            result.parent_id = +parentId;
            result.value = formatTextForID(elementName, id);
            result.activeElementName = capitalizeText(elementName);
            result.id = +id;
    
        } else if (type === constants.SEARCH_REQUEST) {
            result.value = replaceWord(url, '');
        }
    
        return result;

    } catch (error){
        console.error(`An error has occurred ${error.name}: ${error.message}`);
    }
};
import constants from "@/constants/constants";

export default function getCountColumns(quantityElements) {
    if(quantityElements <= constants.ONE_COLUMN.COUNT) {
      return constants.ONE_COLUMN.COLUMN;
    } else if(quantityElements <= constants.TWO_COLUMN.COUNT) {
      return constants.TWO_COLUMN.COLUMN;
    } else {
      return constants.THREE_COLUMN.COLUMN;
    }
}
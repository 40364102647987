<template>
    <div class="asset_type_panel" id="header_asset_type">
        <div class="asset_type_panel_choice">
            <button class="asset_type_panel_button" @click="openAssetsWindow" :disabled="isBadRequestAsseet">
                <div class="asset_type_panel_button-title" v-if="!isBadRequestAsseet">
                    <img :src="getMainActiveAsset?.icon_path" alt=""/>
                    <span v-if="!getActiveSubAsset.length">
                        {{ activeMainAsset?.name }}
                    </span>
                    <span v-else>
                        {{ activeMainAsset?.name }}, {{ activeSubAsset.map(({activeElementName}) => activeElementName).join(', ') }}
                    </span>
                </div>
                <div class="asset_type_panel_button-arrow" v-if="!isBadRequestAsseet">
                    <img src="@/images/sprites/svg/arrow-down.svg" :class="!isOpenWindow ? 'arrow_image-down' : 'arrow_image-down arrow_image-up'">
                </div>
            </button>
        </div>
        <div class="asset_type_panel_window" v-if="isOpenWindow">
            <ul class="asset_type_panel_window_list">
                <AssetTypeItemComponent 
                    v-for="assetType in getDataAssets" 
                    :assetType="assetType"
                    :mainAssetTypeId="activeMainAsset.id"
                    :mainAssetTypeName="activeMainAsset.name"/>
            </ul>
        </div>
    </div>

</template>

<script>
import AssetTypeItemComponent from './AssetItemComponent/AssetTypeItemComponent.vue';

export default {
    name: 'AssetTypePanelComponent',
    data() {
        return {
            isOpenWindow: false,
            dataAssets: [],
            activeMainAsset: null,
            activeSubAsset: [],
        }
    },
    components: {
        AssetTypeItemComponent,
    },
    methods: {
        openAssetsWindow() {
            this.isOpenWindow = !this.isOpenWindow;
        },
        eventClick(e) {
            const target = !e.target.closest('.asset_type_panel');
            if(target) {
                this.isOpenWindow = false;
            }
        }
    },
    watch: {
        isOpenWindow(value) {
            if(value) {
                window.addEventListener('click', this.eventClick)
            } else {
                window.removeEventListener('click', this.eventClick)
            }
        }
    },
    computed: {
        getDataAssets() {
            const dataAssets = this.$store.getters.DATA_ASSETS;

            this.dataAssets = [...dataAssets];
            return this.dataAssets;
        },
        getMainActiveAsset() {
            this.activeMainAsset = this.$store.getters.ACTIVE_MAIN_ASSET;

            // if(this.activeMainAsset?.name && !this.$route.query.assets) { //добавление ассета в URL
                // const assetParamsURL = encodeURI(`${this.activeMainAsset?.name.toLowerCase()}[${this.activeMainAsset?.id}]`);
                // this.$router.push({ query: {assets: assetParamsURL}});
                // console.log('asset URL', assetParamsURL, this.$route.query);
            // }

            return this.activeMainAsset;
        },
        getActiveSubAsset() {
            const activeSubAssets = this.$store.getters.ALL_ASSET_ACTIVE_FILTER(this.activeMainAsset?.asset_type_filter_id);
            this.activeSubAsset = activeSubAssets;
            return this.activeSubAsset || [];
        },
        isBadRequestAsseet() {
            return this.$store.getters.BAD_REQUEST_ASSETS;
        }
    },
}

</script>

<style scoped lang="scss">
    .asset_type_panel {
        position: relative;
        width: 100%;
        max-width: 260px;
        .asset_type_panel_choice {
            width: 100%;
            .asset_type_panel_button {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                max-width: 260px;
                height: 40px;
                padding: 0 16px;
                font-size: 16px;
                font-weight: 500;
                transition: all .3s ease-out;
                outline: none;
                background: #EBEFF5;
                border: 1px solid #EBEFF5;
                border-radius: 10px 0 0 10px;
                &:hover {
                    border: 1px solid #757575;
                }
                img {
                    display: block;
                    width: 16px;
                    height: 16px;
                }
                .asset_type_panel_button-title {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 6px;
                    white-space: nowrap;
                    span {
                        max-width: 185px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
                .asset_type_panel_button-arrow {
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    align-items: center;
                    opacity: 0.5;
                    .arrow_image-up {
                      transform: scaleY(-1);
                    }
                }
            }
        }
        .asset_type_panel_window {
            position: absolute;
            top: 45px;
            left: 0px;
            z-index: 100;
            width: 260px;
            border-radius: 10px;
            border: 1px solid #EBEFF5;
            background-color: #FFFFFF;
            font-size: 14px;
            font-weight: 500;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            box-shadow: 10px 20px 50px rgba(0, 0, 0, 0.15);
            .asset_type_panel_window_list {
                width: 100%;
                list-style: none;
            }
        }
    }
    .min_btn {
        width: 60px;
        min-width: 60px;
        .asset_type_panel_choice {
            .asset_type_panel_button {
                gap: 4px;
                padding: 0 2px;
                justify-content: center;
            }
            span {
                display: none;
            }

        }
    }
</style>
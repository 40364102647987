<template>
    <div class="panel_form_company">
      <div class="panel_form_company-title">
        Company
      </div>
      <div class="panel_form_company-input">
        <input type="text" placeholder="Where do you work?" v-model="companyText" @change="validateCompany" ref="company_input">
      </div>
      <div class="panel_form_company-info">
        <span class="panel_form_company-error" v-if="isNotCorrect">
            The company name must contain the letters
        </span>
      </div>
    </div>
</template>

<script>
import constants from '@/constants/constants';

export default {
    name: 'CompanyInputComponent',
    data () {
        return {
            companyText: '',
            isNotCorrect: false,
        }
    },
    props: {
        changeCompanyParameters: {
            type: Function,
            required: true,
        },
    },
    methods: {
        validateCompany() {
            const companyNameInput = this.$refs.company_input;
            let validCompanyName;
            if(this.companyText) {
                validCompanyName = !constants.COMPANY_NAME_REGEXP.test(this.companyText);
            }
            if(validCompanyName) {
                companyNameInput.classList.add('input_not_valid');
            } else {
                companyNameInput.classList.remove('input_not_valid');
            }
            this.isNotCorrect = validCompanyName;
            this.changeCompanyParameters(this.companyText, this.isNotCorrect);
            return this.isNotCorrect;
        },
    },
};
</script>

<style scoped lang="scss">
.panel_form_company {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 10px;
    .panel_form_company-input {
        position: relative;
        width: 100%;
        margin-top: 10px;
        input {
            width: 100%;
            height: 40px;
            background-color: #EBEFF5;
            border: 1px solid #EBEFF5;
            border-radius: 10px;
            border-left: 1px solid #75757520;
            padding-right: 36px;
            padding-left: 10px;
            font-size: 16px;
            font-weight: 500;
            transition: all .3s ease-out;
            outline: none;
            &:hover {
                border: 1px solid #757575;
            }
            &::placeholder {
                font-size: 16px;
                font-weight: 500;
            }
        }
        .input_not_valid {
            border: 1px solid #FF2929;
            background-color: #FFF3F3;
            &:hover {
                border: 1px solid #FF2929;
            }
        }
    }
    .panel_form_company-info {
        height: 17px;
        .panel_form_company-error {
            font-size: 14px;
            color: #FF2929;
        }
    }
}
</style>
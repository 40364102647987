import { deepFindChildFilter, deepFindDefaultFilter } from '@/utils/deepFindFilter/deepFindFilter';
import isDuplicateElement from '@/utils/restoreActiveElements/restoreActiveElements';
import apiRequest from '@/utils/apiRequest/apiRequest';
import constants from '@/constants/constants';


const state = {
    dataFilters: [],
    activeFilters: [],
    childFiltersElements: {},
    // assetFilterActive: {},
};

const getters = {
  DATA_FILTERS: state => {
    return state.dataFilters;
  },
  ACTIVE_FILTERS: state => {
    return state.activeFilters;
  },
  ACTIVE_FILTERS_BY_ID: (state) => (id) => {
    return state.activeFilters.find(filter => filter.id === id);
  },
  PARENT_FILTERS_BY_ID: (state) => (idFilter, idSubParent) => {
    return deepFindDefaultFilter(state.dataFilters, idFilter);
  },
  CHILD_FILTER_BY_ID: (state) => (idFilter) => {
    return state.childFiltersElements[idFilter];
  },
  ALL_ACTIVE_FILTERS: state => {
    return state.activeFilters;
  },
  ALL_ASSET_ACTIVE_FILTER: (state) => (id) => {
    return state.activeFilters.filter(({parent_id}) => parent_id === id);
  },
};

const addParentId = function(elements, parentId, subParentId) {
  elements.forEach(child => {
    child.parentId = parentId;
    child.subParentId = subParentId;
  });
  return elements;
}

const mutations = {
  SET_DATA_FILTERS: (state, payload) => {
    state.dataFilters = payload;
  },
  SET_CHILD_FILTERS_ELEMENTS: (state, payload) => {
    const arr = payload.map(({children_filter, filter_items, id}) => {
      const idParent = id;
      return children_filter ? children_filter.map((filterChild) => addParentId(filterChild.filter_items, idParent, filterChild.id)) : addParentId(filter_items, idParent)
    }).flat(2);
    arr.forEach((filter) => {
      state.childFiltersElements[filter.id] = filter;
    })
  },
  ADD_ACTIVE_FILTERS: (state, payload) => {
    state.activeFilters.push(payload);
  },
  ADD_ACTIVE_FILTERS_RESTORE: (state, payload) => {
    if(!payload || isDuplicateElement(state.activeFilters, payload.id)) return;
  
    const findFilter = deepFindChildFilter(state.dataFilters, payload);
    if(!findFilter) return;

    payload.activeElementName = findFilter.name;

    state.activeFilters.push(payload);
  },
  REMOVE_ACTIVE_FILTERS: (state, payload) => {
    state.activeFilters = state.activeFilters.filter(filter => {
        return  filter.value !== payload.value;
    });
  },
  REMOVE_ALL_ACTIVE_FILTERS: (state, payload) => {
    state.activeFilters = [];
  },
  // ADD_ASSET_ACTIVE_FILTER: (state, payload) => { //удалить
  //   if(payload.value) {
  //     state.activeFilters = state.activeFilters.filter(filter => {   //подумать над очисткой активных ассетов при выборе другого - причина? повтор методов выше
  //       if(filter.name === state.assetFilterActive.name) {
  //         return  filter.value !== state.assetFilterActive.value;
  //       }
  //       return true;
  //     });
  //     state.activeFilters.push(payload);     //подумать над добавлением нового активного фильтра - причина? повтор методов выше;
  //     state.assetFilterActive = payload;
  //   }
  // },
};

const actions = {
  GET_DATA_FILTERS: async ({getters, commit}, payload) => {
      try {
        commit('SET_IS_FILTERS_DATA', [constants.FILTERS, true]);
        const response = await apiRequest({
          url: `/filter_api/filter?asset_type_id=${getters.ACTIVE_MAIN_ASSET.id}`,
          method: 'GET',
          headers: {
            "Cache-Control": "no-cache",
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": `Bearer ${getters.ACCESS_TOKEN}`,
          },
        });
        commit('SET_IS_FILTERS_DATA', [constants.FILTERS, false]);
        const res = response.data;
        commit('SET_DATA_FILTERS', res);
        commit('SET_CHILD_FILTERS_ELEMENTS', res);
      } catch (err) {
        commit('SET_IS_FILTERS_DATA', [constants.FILTERS, false]);
        commit('SET_IS_BAD_REQUEST_FILTERS', [constants.FILTERS, true]);
        console.error(`${err.name}: ${err.message}`);
      }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
}
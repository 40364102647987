<template>
    <div class="collect__images">
        <div class="collect__images__title">
            Сollect and download
        </div>
        <div class="collect__images__body">
            <CollectDropareaComponent :isEmptyCollectData="isEmptyCollectData" :isVerticalResizeEnable="isVerticalResizeEnable"/>
            <CollectNavigationButtons v-if="isCollectData"/>
        </div>
    </div>
</template>

<script>
import CollectDropareaComponent from './CollectDropareaComponent/CollectDropareaComponent.vue';
import CollectNavigationButtons from './CollectNavigationButtons/CollectNavigationButtons.vue';

export default {
    name: "CollectImagesComponent",
    data() {
        return {
            isCollectData: false,
        }
    },
    props: {
        isVerticalResizeEnable: {
            type: Object,
            required: false,
        },
    },
    components: {
        CollectDropareaComponent,
        CollectNavigationButtons,
    },
    methods: {
        isEmptyCollectData(value) {
            this.isCollectData = value;
        }
    }
}
</script>

<style scoped lang="scss">
    .collect__images {
        border-radius: 10px 0 0 10px;
        background: #EBEFF5;
        position: relative;
        padding: 20px 16px 20px 20px;
        max-height: 100%;
        flex-shrink: 2;
        overflow-y: hidden;
        .collect__images__title {
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: 700;
            text-align: left;
        }
        .collect__images__body {
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding-bottom: 20px;
            gap: 8px;
        }
    }
</style>
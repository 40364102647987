export function deepFindFilter(filters, activeFilter) {
  if(!activeFilter) return;
  
  let stageFilter = null;
  let foundFilter = filters.find((filter) => {
    if(filter.children_filter && filter.id === activeFilter.parent_id) {
      stageFilter = filter;
    }
    return filter.id === activeFilter.parent_id
  });

  if(stageFilter) {
    foundFilter = stageFilter.children_filter.find(({filter_items}) => {
      const childFilter = filter_items.find(({id}) => {
        return id === activeFilter.id
      })
      return childFilter;
    });
  }

  return foundFilter;
}

export function deepFindChildFilter(filters, activeFilter) {
  if(!activeFilter) return;
  try {
    const foundFilter = deepFindFilter(filters, activeFilter);
    const childFilter = foundFilter.filter_items.find(({id}) => id === activeFilter.id);
    return childFilter;
  } catch(error) {
    console.error(`An error has occurred ${error.name}: ${error.message}`);
  }
}

export function deepFindDefaultFilter(filters, filterId) {
  let stageFilter;
  let foundFilter = filters.find((filter) => {
    if(filter.children_filter) {
      stageFilter = filter;
    }
    return filter.id === filterId
  });
  if(!foundFilter) {
    foundFilter = stageFilter?.children_filter.find(({id}) => id === filterId);
  }
  return foundFilter;
}
<template>
    <ul 
        :class="{'filters_panel_type-body': true, 'view_checkbox': getFiltersView() !== 'tag'}" 
        v-if="isFilterTypeOpen && getOnlyFilters().length" >
        <FilterSubtypeComponent v-for="filterSubtype in filters" 
            :filterSubtype="filterSubtype" 
            :filterValue="filterValue"
            :updateParametersURL="updateParametersURL"
            :isProportion="isProportion"
            :filterTypeView="getFiltersView()"/>
    </ul>
</template>
<script>
import constants from '@/constants/constants';
import FilterSubtypeComponent from './../FilterSubtypeComponent/FilterSubtypeComponent.vue';

export default {
  name: 'CommonTypesComponent',
  data() {
    return {
      filters: [],
      isProportion: false,
    }
  },
  components: {
    FilterSubtypeComponent,
  },
  props: {
    filterValue: {
        type: Object,
        required: true,
    },
    updateParametersURL: {
        type: Function,
        required: true,
    },
    // checkActiveFilters: {
    //     type: Function,
    //     required: true,
    // },
    isFilterTypeOpen: {
        type: Boolean,
        required: true,
    },
  },
  methods: {
    getOnlyFilters() {
      this.isProportion = constants.FILTER_PROPORTION === this.filterValue.name.toLowerCase();
      this.filters = this.filterValue.provider ? [] : this.filterValue.filter_items;
      return this.filters;
    },
    getFiltersView() {
      return this.filterValue.filter_type;
    },
  },
};
</script>

<style scoped lang="scss">
    .filters_panel_type-body {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      gap: 6px;
      align-content: center;
      justify-content: flex-start;
      align-items: flex-start;
      margin-left: 10px;
      width: 100%;
    }
    .view_checkbox {
      flex-direction: column;
      align-content: flex-start;
      row-gap: 12px;
      width: 100%;
    }
</style>
<template>
    <div class="sort_component">
        <div class="sort_component_item sort_popular_component">
            <div class="sort_popular_btn" @click="openSortWindow">
                <div class="sort_popular_title">
                    {{ activeSort }}
                </div>
                <div class="filters_panel_type-arrow">
                    <img src="@/images/sprites/svg/arrow-down.svg" :class="!isOpenSortWindow ? 'arrow_image-down' : 'arrow_image-down arrow_image-up'">
                </div>
            </div>
            <div class="sort_popular_window" v-if="isOpenSortWindow">
                <ul class="sort_popular_window_list">
                    <li class="sort_popular_window_item">
                        <CustomRadioComponent 
                            :radioInfo="radioInfo[0]"
                            :radioChangeFunction="radioChangeFunction"/>
                    </li>
                    <li class="sort_popular_window_item">
                        <CustomRadioComponent 
                            :radioInfo="radioInfo[1]"
                            :radioChangeFunction="radioChangeFunction"/>
                    </li>
                    <li class="sort_popular_window_item">
                        <CustomRadioComponent 
                            :radioInfo="radioInfo[2]"
                            :radioChangeFunction="radioChangeFunction"/>
                    </li>
                </ul>
            </div>
        </div>
        <div class="sort_select_btn" @click="openSelectWindow">
            <div class="sort_select_icon">
                <img src="@/images/icons/no-name.svg" alt="">
            </div>
            <div class="filters_panel_type-arrow">
              <img src="@/images/sprites/svg/arrow-down.svg" class="arrow_image-down" v-if="!isOpenSelectWindow">
              <img src="@/images/sprites/svg/arrow-down.svg" class="arrow_image-up" alt="" v-else>
            </div>
        </div>
    </div>
</template>

<script>
import CustomRadioComponent from '@/components/Others/CustomRadioComponent/CustomRadioComponent.vue';

export default {
    name: 'SortComponent',
    data () {
        return {
            isOpenSortWindow: false,
            isOpenSelectWindow: false,
            activeSort: 'Popular',
            radioInfo: [{
                name: 'Popular',
                block_name: 'sort_window',
                checked: true,
            },
            {
                name: 'Fresh',
                block_name: 'sort_window',
                checked: false,
            },
            {
                name: 'Rating',
                block_name: 'sort_window',
                checked: false,
            }],
        }
    },
    components: {
        CustomRadioComponent,
    },
    methods: {
        openSortWindow() {
            this.isOpenSelectWindow = false;
            this.isOpenSortWindow = !this.isOpenSortWindow;
        },
        openSelectWindow() {
            this.isOpenSortWindow = false;
            this.isOpenSelectWindow =!this.isOpenSelectWindow;
        },
        eventClick(e) {
            const target = !e.target.closest('.sort_popular_component')
            if(target) {
                this.isOpenSortWindow = false;
            }
        },
        radioChangeFunction(e) {
            this.activeSort = e.target.value;
            this.radioInfo = this.radioInfo.map(sortFilter => {
                sortFilter.checked = sortFilter.name === this.activeSort;
                return sortFilter;
            })
        }
    },
    watch: {
        isOpenSortWindow(value) {
            if(value) {
                window.addEventListener('click', this.eventClick)
            } else {
                window.removeEventListener('click', this.eventClick)
            }
        }
    }
}
</script>

<style scoped lang="scss">
    .sort_component {
        display: flex;
        gap: 18px;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        min-width: 80px;
        width: 15%;
        .sort_component_item {
            position: relative;
            .sort_popular_btn {
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;
                font-size: 16px;
                font-weight: 500;
                gap: 4px;
                cursor: pointer;
                &:hover {
                    .sort_popular_title,
                    .filters_panel_type-arrow {
                        filter: brightness(0) saturate(100%) invert(34%) sepia(97%) saturate(2994%) hue-rotate(196deg) brightness(105%) contrast(103%);
                    }
                }
                .sort_popular_title {
                    min-width: 60px;
                    text-align: right;
                }
                .filters_panel_type-arrow {
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    align-items: center;
                    opacity: 0.4;
                    .arrow_image-up {
                      transform: scaleY(-1);
                    }
                }
            }
            .sort_popular_window {
                position: absolute;
                top: 30px;
                left: -40px;
                z-index: 100;
                width: 140px;
                height: 112px;
                border-radius: 8px;
                background-color: #FFFFFF;
                display: flex;
                justify-content: center;
                align-items: center;
                border: solid 1px #EBEFF5;
                box-shadow: 2px 2px 16px rgba(0, 0, 0, 0.1);
                .sort_popular_window_list {
                    list-style: none;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 8px;
                    padding: 0;
                    margin: 0;
                    cursor: default;
                }
            }
        }
        .sort_select_btn {
            position: relative;
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            font-size: 16px;
            font-weight: 500;
            gap: 4px;
            cursor: pointer;
            .sort_select_icon {
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;
            }
            .filters_panel_type-arrow {
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;
                opacity: 0.4;
                .arrow_image-up {
                  transform: scaleY(-1);
                }
            }
        }
        .sort_select_btn:hover {
            .sort_select_icon,
            .filters_panel_type-arrow {
                filter: brightness(0) saturate(100%) invert(34%) sepia(97%) saturate(2994%) hue-rotate(196deg) brightness(105%) contrast(103%);
            }
        }
    }
</style>
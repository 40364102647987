<template>
    <div class="work_progress_container" v-if="isActiveAsset">
        <img src="@/images/bad_request/back-for-other.jpg" alt="">
    </div>
</template>

<script>

export default {
  name: 'WorkInProgress',
  computed: {
    isActiveAsset() {
        const activeMainAsset = this.$store.getters.ACTIVE_MAIN_ASSET;
        return activeMainAsset?.name !== 'Images';
    }
  }
};
</script>

<style scoped lang="scss">
    .work_progress_container {
        width: 100%;
        height: 100%;
        font-size: 32px;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        img {
            width: 70%;
        }
    }
</style>
<template>
    <div class="selected_image_container" >
        <div class="selected_image_container_view">
            <div class="selected_image_container_item" v-show="!isLoadingImage">
                <img 
                  :src="getAsideImage?.image_path"
                  :onLoad="imageLoadStop"
                  :onError="imageLoadError"
                  v-show="!notFileImage"
                  alt="View Element">
                <img class="not_file_selected" src="@/images/sprites/svg/not_file.svg" v-if="notFileImage">
            </div>
            <LoaderComponent v-if="isLoadingImage"/>
        </div>
        <div class="selected_image_container_description" v-show="imageData?.id">
            <div class="image_description_total" v-if="imageData?.resolution">
                <SelectedItemsInfo :infoData="imageData"/>
                <ImageNavigation :imageData="imageData"/>
            </div>
            <div class="image_description_additionally" v-if="imageData?.resolution">
                <SelectedItemsTags :imageData="imageData"/>
            </div>
        </div>
    </div>
</template>

<script>
import SelectedItemsTags from './SelectedItemsTags/SelectedItemsTags.vue';
import SelectedItemsInfo from './SelectedItemsInfo/SelectedItemsInfo.vue';
import ImageNavigation from '@/components/Pages/HomePageComponents/MainImagesComponents/ImageComponent/ImageNavigation.vue';
import LoaderComponent from '@/components/Others/LoaderComponent.vue';
import constants from '@/constants/constants';

export default {
    name: "SelectedImageContainer",
    data() {
        return {
            notFileImage: true,
            imageData: null,
            isLoadingImage: false,
        };
    },
    components: {
        SelectedItemsTags,
        SelectedItemsInfo,
        ImageNavigation,
        LoaderComponent,
    },
    methods: {
        imageLoadStop() {
            // console.log(e)
            this.isLoadingImage = false;
        },
        imageLoadError() {
            this.notFileImage = true;
            this.isLoadingImage = false;
        }
    },
    computed: {
      getAsideImage() {
        this.imageData = this.$store.getters.ASIDE_IMAGE_DATA;
        const activeMainAsset = this.$store.getters.ACTIVE_MAIN_ASSET;

        if(activeMainAsset && activeMainAsset?.name.toLowerCase() !== constants.MAIN_ASSET_DEFAULT ) {  //заглушка для того чтобы убрать файлы другого ассета
            this.imageData = null;
            setTimeout(() => {
                this.notFileImage = true;
                this.isLoadingImage = false;
            }, 200)
        }

        return this.imageData;
      }
    },
    watch: {
        imageData(newValue, oldValue) {
            if(oldValue !== null) {
                this.notFileImage = false;
            }
            if(oldValue?.id !== newValue?.id) {
                this.isLoadingImage = true;
            }
        }
    },
    mounted() {
        if(this.imageData?.id) {
            this.notFileImage = false;
        }
    }
}
</script>

<style lang="scss">
    .selected_image_container {
        border-radius: 10px;
        background: #EBEFF5;
        position: relative;
        height: 100%;
        //min-height: 320px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .selected_image_container_view {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-height: 320px;
            border-radius: 10px;
            flex-grow: 1;
            flex-shrink: 452;
            background-color: #FEFEFE;
            background-image: -webkit-linear-gradient(45deg, #dcdcdc 25%, transparent 25%, transparent 75%, #dcdcdc 75%, #dcdcdc), -webkit-linear-gradient(45deg, #dcdcdc 25%, transparent 25%, transparent 75%, #dcdcdc 75%, #dcdcdc);
            background-image: -moz-linear-gradient(45deg, #dcdcdc 25%, transparent 25%, transparent 75%, #dcdcdc 75%, #dcdcdc), -moz-linear-gradient(45deg, #dcdcdc 25%, transparent 25%, transparent 75%, #dcdcdc 75%, #dcdcdc);
            background-image: -o-linear-gradient(45deg, #dcdcdc 25%, transparent 25%, transparent 75%, #dcdcdc 75%, #dcdcdc), -o-linear-gradient(45deg, #dcdcdc 25%, transparent 25%, transparent 75%, #dcdcdc 75%, #dcdcdc);
            background-image: -ms-linear-gradient(45deg, #dcdcdc 25%, transparent 25%, transparent 75%, #dcdcdc 75%, #dcdcdc), -ms-linear-gradient(45deg, #dcdcdc 25%, transparent 25%, transparent 75%, #dcdcdc 75%, #dcdcdc);
            background-image: linear-gradient(45deg, #dcdcdc 25%, transparent 25%, transparent 75%, #dcdcdc 75%, #dcdcdc), linear-gradient(45deg, #dcdcdc 25%, transparent 25%, transparent 75%, #dcdcdc 75%, #dcdcdc);
            -webkit-background-size: 20px 20px;
            -moz-background-size: 20px 20px;
            background-size: 20px 20px;
            background-position: 0 0, 10px 10px;
            .selected_image_container_item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-content: center;
                align-items: center;
                width: 100%;
                max-width: 100%;
                overflow: hidden;
                height: 100%;
                padding: 5px;
                img {
                    width: 100%;
                    height: 100%;
                    max-height: 100%;
                    pointer-events: auto;
                    object-fit: contain;
                }
                .not_file_selected {
                    width: 50%;
                }
            }
        }
        .selected_image_container_description {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            align-content: center;
            flex-shrink: 0;
            gap: 10px;
            font-size: 14px;
            padding: 18px 10px 10px 18px;
            min-height: 127px;
            overflow: hidden;
            .image_description_total {
                display: flex;
                justify-content: space-between;
                align-content: center;
                width: 100%;
                padding-bottom: 12px;
                padding-right: 8px;
                border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
                .image_component_nav {
                    position: static;
                    opacity: 1;
                    visibility: visible;
                    gap: 24px;
                    .image_component_btn {
                        width: 20px;
                        height: 20px;
                        background-color: transparent;
                        img {
                            width:  20px;
                            height: 20px;
                        }
                    }
                    .image_component_btn-collection {
                        img {
                            width:  30px;
                            height: 30px;
                        }
                    }
                    .image_component_btn-download {
                        img {
                            width:  26px;
                            height: 26px;
                        }
                    }
                }
            }
            .image_description_additionally {
                width: 100%;
                min-height: 46px;
                overflow-y: auto;
                scrollbar-gutter: stable;
            }
        }
        .update_description_container {
            flex-shrink: 1;
            min-height: 217px;
        }
    }
</style>
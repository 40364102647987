<template>
  <main class="main_content">
    <MainDirectory/>
  </main>
  <AsideDirectory/>
</template>

<script>
// @ is an alias to /src
import AsideDirectory from '@/components/Pages/HomePageComponents/AsideComponent/AsideDirectory.vue';
import MainDirectory from '@/components/Pages/HomePageComponents/MainDirectory.vue';
import constants from '@/constants/constants';

let horizontalResizeParameters = '';
let verticalResizeParameters = '';

export default {
  name: 'HomeView',
  components: {
    MainDirectory,
    AsideDirectory
  },
  beforeRouteEnter(to, from, next) {
    const app = document.getElementById('app');
    app.classList.add('app_search_page');

    if(horizontalResizeParameters) {
      app.style.gridTemplateColumns = horizontalResizeParameters;
    }
    next(() => {
      if(verticalResizeParameters) {
        const asideBlock = app.querySelector('#aside_directory_block');
        asideBlock.style.gridTemplateRows = verticalResizeParameters;
      }
    });
    
  },
  beforeRouteLeave(to, from, next) {
    if(to.name !== constants.PATH_NAME.AUTHORIZATION && to.name !== constants.PATH_NAME.SIGN_UP) {
      const app = document.getElementById('app');
      app.classList.remove('app_search_page');

      const asideBlock = app.querySelector('#aside_directory_block');
      verticalResizeParameters = asideBlock.style.gridTemplateRows;

      horizontalResizeParameters = app.style.gridTemplateColumns;
      app.style.gridTemplateColumns = '';
    }
    next();
  }
};
</script>

<style scoped lang="scss">
    .main_content {
        flex-basis: calc(100% - 16.66667vw);
        max-width: 100%;
        padding: 20px 10px 0 0px;
        position: relative;
        //overflow: hidden;
    }

</style>

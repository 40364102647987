<template>
        <div class="authorization_panel">
          <div class="authorization_panel_title">
            Log in to your account
          </div>
          <PopupNotSuccesEnter v-if="isBadRequest" :message="errorMessage"/>
          <div class="authorization_panel_form">
            <EmailPanelComponent
              :changeEmailParameters="changeEmailParameters"/>
            <PasswordPanelComponent
              :titleName="'Password'"
              :placeholderName="'Enter a password'"
              :isNeedInfoCorrect="true"
              :messageCorrectInfo="'Password is not entered correctly'"
              :validatePassword="validatePassword"/>
            <ButtonAuthorization
              :nameButton="'Log In'"
              :clickFunction="authorizationUser"/>
            <div class="authorization_panel_help">
              Forgot password?
            </div>
            <div class="authorization_panel_singup">
                <span>Don’t have an account?</span> 
                <div class="sing_up_wrapper" @click="startSingUp">
                    Sign Up
                </div>
            </div>
          </div>
        </div>
</template>

<script>
import EmailPanelComponent from '@/components/Others/InputPanels/EmailPanelComponent/EmailPanelComponent.vue';
import PasswordPanelComponent from '@/components/Others/InputPanels/PasswordPanelComponent/PasswordPanelComponent.vue';
import ButtonAuthorization from '@/components/Others/ButtonAuthorization/ButtonAuthorization';
import PopupNotSuccesEnter from '@/components/Others/InputPanels/PopupNotSuccesEnter/PopupNotSuccesEnter';
import constants from '@/constants/constants';
import { useRouter } from 'vue-router';

export default {
    name: 'AuthorizationPanelLogIn',
    components: {
        EmailPanelComponent,
        PasswordPanelComponent,
        ButtonAuthorization,
        PopupNotSuccesEnter,
    },
    data () {
        return {
            email: '',
            password: '',
            errorMessage: '',
            isViewPassword: false,
            isNotCorrectEmail: true,
            isNotCorrectPass: true,
            isBadRequest: false,
            router: useRouter(),
        }
    },
    methods: {
        changeEmailParameters(inputData, isValid) {
            this.email = inputData;
            this.isNotCorrectEmail = isValid;
        },
        authorizationUser() {
            if(!this.canLogIn()) return;
            const authData = {
                username: this.email,
                password: this.password,
            }
            this.$store.dispatch('AUTHORIZATION_USER', authData).then(res =>{
                const status = res.status;
                const {STATUS_CODE, PATH_NAME} = constants;
                if(status === STATUS_CODE.BAD_LOG_IN || status === STATUS_CODE.BAD_REQUEST || status === STATUS_CODE.FORBIDDEN) {
                    if(status === STATUS_CODE.FORBIDDEN) {
                        this.errorMessage = res.data.detail;
                    }
                    this.isBadRequest = true;
                }
                if(status === STATUS_CODE.CREATED_SUCCESS) {
                    this.router.push({
                        path: `/${PATH_NAME.SEARCH}`,
                    })
                }
            })
        },
        validatePassword(inputData) {
            this.password = inputData;
            let validPass = this.password.length <= constants.MIN_VALID_SYMBOL_PASSWORD || !this.password.length;
            this.isNotCorrectPass = validPass;
            return !this.isNotCorrectPass;
        },
        canLogIn() {
            const isCorrect = !this.isNotCorrectPass && !this.isNotCorrectEmail;
            // const isNotEmptyInput = !!this.email.length && !!this.password.length && !!this.company.length && !!this.name.length;
            return isCorrect;
        },
        startSingUp() {
            this.$router.push(constants.PATH_NAME.SIGN_UP)
        },
    },
};
</script>
  
<style lang="scss">
    .authorization_panel {
        position: relative;
        width: 500px;
        min-height: 430px;
        background-color: #FFFFFF;
        border-radius: 10px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        padding: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
        margin: 30px;
        .authorization_panel_title {
            font-size: 20px;
            font-weight: 700;
            color: #111111;
        }
        .authorization_panel_form {
            font-size: 15px;
            font-weight: 700;
            color: #111111;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .authorization_panel_help {
                margin-top: 10px;
                font-weight: 600;
                cursor: pointer;
                &:hover {
                    color: #0085FF;
                }
            }
            .authorization_panel_singup {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 4px;
                margin-top: 20px;
                color: #111111;
                span {
                    font-weight: 500;
                    color: #757575;
                }
                .sing_up_wrapper {
                    cursor: pointer;
                    &:hover {
                        color: #0085FF;
                    }
                }
            }
        }
    }
</style>

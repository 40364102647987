import apiRequest from '@/utils/apiRequest/apiRequest';

const state = {
    dataSmartFilters: [],
    activeSmartFilters: [],
};

const getters = {
  DATA_SMART_FILTERS: state => {
    return state.dataSmartFilters;
  },
  ACTIVE_SMART_FILTERS: state => { // возможно не нужен
    return state.activeSmartFilters;
  },
};

const mutations = {
  SET_DATA_SMART_FILTERS: (state, payload) => {
    state.dataSmartFilters = payload;
  },
  ADD_ACTIVE_SMART_FILTERS: (state, payload) => { // возможно не нужен
    state.activeSmartFilters.push(payload); // возможно не нужен
  },
  REMOVE_ACTIVE_SMART_FILTERS: (state, payload) => { // возможно не нужен
    state.activeSmartFilters = state.activeSmartFilters.filter(smart_filter => { // возможно не нужен
      if(smart_filter.name === payload.name) {
        return  smart_filter.value !== payload.value;
      }
      return true;
    });
  },
  REMOVE_ALL_ACTIVE_SMART_FILTERS: (state, payload) => { // возможно не нужен
    state.activeSmartFilters = [];
  },
};

const actions = {
  GET_DATA_SMART_FILTERS: async ({getters, commit}, payload) => {
    try {
      const response = await apiRequest({
        url: `/smart_filters/?asset_type_id=${getters.ACTIVE_MAIN_ASSET.id}`,
        method: 'GET',
        headers: {
          "Cache-Control": "no-cache",
          "Authorization": `Bearer ${getters.ACCESS_TOKEN}`,
        },
      });
      const res = response.data;
      commit('SET_DATA_SMART_FILTERS', res);
    } catch (err) {
      console.error(`${err.name}: ${err.message}`);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
}
<template>
    <ul class="filters_panel_type_body-games" v-if="isFilterTypeOpen">
      <GameSubtypeComponent v-for="filterSubtype in getDataGames" 
        :filterSubtype="filterSubtype"
        :updateParametersURL="updateParametersURL"
        :parentType="filterType" />
      <GamesGetButtonComponent :getAllDataGames="getAllDataGames" v-if="viewBtnAll"/>
      <div class="popup_container" ref="pop">
        <GamesPopupComponent v-if="viewAllGames"
          :filterType="filterType"
          :allGamesView="allGamesView"
          :updateParametersURL="updateParametersURL"
          :searchGame="searchGame"
        />
      </div>
    </ul>
</template>
<script>
import GameSubtypeComponent from './../GameSubtypeComponent/GameSubtypeComponent.vue';
import GamesGetButtonComponent from './../GamesGetButtonComponent/GamesGetButtonComponent.vue';
import GamesPopupComponent from './../GamesPopupComponent/GamesPopupComponent.vue';
import constants from '@/constants/constants';

export default {
  name: 'GameFilterComponent',
  data() {
    return {
      dataGames: [],
      viewAllGames: false,
      allGamesView: [],
      allGames: [],
      viewBtnAll: false,
    }
  },
  components: {
    GameSubtypeComponent,
    GamesGetButtonComponent,
    GamesPopupComponent,
  },
  props: {
    filterType: {
        type: Object,
        required: true,
    },
    isFilterTypeOpen: {
        type: Boolean,
        required: true,
    },
    updateParametersURL: {
        type: Function,
        required: true,
    },
    isOpenPopUp: {
        type: Object,
        required: true,
    },
  },
  methods: {
    openPopupGames(event) {
        this.viewAllGames = !this.viewAllGames;
        const offsetY = window.innerHeight - event.clientY > constants.POPUP_DISTANCE_GAME.MAX_SCREEN_DISTANCE ? constants.POPUP_DISTANCE_GAME.DISTANCE_UP : constants.POPUP_DISTANCE_GAME.DISTANCE_DOWN;
        if(this.viewAllGames) {
            this.$refs.pop.style.top = event.clientY - offsetY + 'px';
        }
    },
    eventClick(e) {
        const target = !e.target.closest('.all_games_popup');
        if(target) {
            this.viewAllGames = false;
        }
    },
    getAllDataGames(e) {
      if(this.viewAllGames) {
        return;
      }
      this.isOpenPopUp.games = true;
      this.hidePopUp = false;
      this.$store.dispatch('GET_ALL_DATA_GAMES', '').then(() => {
        const arrAllGames = this.$store.getters.ALL_DATA_GAMES;
        this.allGamesView = [...arrAllGames.game_name];
        this.allGames = [...this.allGamesView];

        if(this.allGames.length) {
          this.openPopupGames(e);
        }
      });
    },
    searchGame(searchWord) {
      const screenSearchWord = searchWord.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
      if(!!searchWord) {
        this.allGamesView = this.allGames.filter(element => {
          const nameGame = new RegExp(screenSearchWord,"gi");
          return element.name.match(nameGame);
        })
      } else {
        this.allGamesView = this.allGames;
      }
    }
  },
  computed: {
    getDataGames() {
      this.dataGames = this.$store.getters.DATA_GAMES[0]['game_name'];
      this.viewBtnAll = this.dataGames.length >= constants.DEFAULT_MIN_GAME_NUMBER;

      return this.dataGames;
    }
  },
  watch: {
    viewAllGames(value) {
        if(value) {
            window.addEventListener('click', this.eventClick)
        } else {
            window.removeEventListener('click', this.eventClick)
        }
    }
  }
};
</script>
<style scoped lang="scss">
  .filters_panel_type_body-games {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    row-gap: 12px;
    width: 100%;
    position: relative;
    overflow: visible;
    margin-left: 10px;
    .popup_container {
      position: fixed;
      left: 350px;
      z-index: 99;
    }
  }
</style>
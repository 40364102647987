const state = {
    dataSearchText: '',
};

const getters = {
  DATA_SEARCH_TEXT: state => {
    return state.dataSearchText;
  },
};

const mutations = {
  SET_DATA_SEARCH_TEXT: (state, payload) => {
    state.dataSearchText = payload;
  },
  REMOVE_SEARCH_TEXT: (state, payload) => {
    state.dataSearchText = '';
  },
};

export default {
  state,
  getters,
  mutations,
}
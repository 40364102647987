<template>
  <StartPageCompontent>
    <SignupPanelComponent/>
  </StartPageCompontent>
</template>

<script>
import StartPageCompontent from '@/components/Pages/LoginPages/StartPageCompontent/StartPageCompontent.vue';
import SignupPanelComponent from '@/components/Pages/LoginPages/SignupPageComponents/SignupPanelComponent/SignupPanelComponent.vue';

export default {
  name: 'SignUpView',
  components: {
    StartPageCompontent,
    SignupPanelComponent,
  },
};
</script>

<style scoped lang="scss">
</style>

<template>
    <aside class="aside_directory" ref="aside" @click="resizeAsidePanel" id="aside_directory_block">
            <div class="aside_directory_first" id="selected_image_container_id">
                <SelectedImageContainer/>
                <VerticalResizeLine :isVerticalResizeEnable="isVerticalResizeEnable"/>
            </div>
            <CollectImagesComponent id="collect_images_component_id" :isVerticalResizeEnable="isVerticalResizeEnable"/>
    </aside>
</template>

<script>
import SelectedImageContainer from './SelectedImageContainer/SelectedImageContainer.vue';
import CollectImagesComponent from './CollectImagesCompotent/CollectImagesComponent.vue';
import VerticalResizeLine from '@/utils/resizer/VerticalResizeLine.vue'

export default {
    name: "AsideComponent",
    components: {
        SelectedImageContainer,
        CollectImagesComponent,
        VerticalResizeLine,
    },
    data() {
        return {
            isVerticalResizeEnable: {
                value: false,
            }
        }
    },
    props: {
    },
    methods: {
        resizeAsidePanel() {
            // const w = this.$refs.aside.clientWidth;
            // if(window.outerWidth-340-45 <= w) return;
            // console.log(this.$refs.aside.style.width)
            // console.log(window.outerWidth)
            // this.$refs.aside.style.width = this.$refs.aside.offsetWidth + 20 + 'px';
            // this.$refs.aside.style.marginLeft = '200'

            // console.log(this.$refs.aside.parentNode.style);
        },
    }
}
</script>

<style scoped lang="scss">
    .aside_directory {
        ::-webkit-scrollbar {
          background-color: #ffffff !important;
        }
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: 66% 1fr;
        align-items: stretch;
        gap: 10px;
        margin-top: 10px;
        .aside_directory_first {
            position: relative;
        }
    }
</style>

export default function isDuplicateElement(store, elementId) {
    return !!store.find(({id}) => id === elementId);
}

export function restoreActiveElements(storeAllElements, storeElements, storeActive, element) {
    if(isDuplicateElement(storeActive, element.id)) return;

    let findElement = storeElements.find(({id}) => id === element.id);
    if(!findElement) {
        findElement = storeAllElements.find(({id}) => id === element.id);
        element.activeElementName = findElement.name;
        storeElements.push(findElement);
    }
    element.activeElementName = findElement.name;
    storeActive.push(element);
}

<template>
    <div class="filters_stage_type-body" v-if="isFilterTypeOpen">
        <div class="filters_stage_type-title">
            {{ filterStageType.name }}
        </div>
        <CommonTypesComponent
          :filterValue="filterStageType"
          :updateParametersURL="updateParametersURL"
          :isFilterTypeOpen="isFilterTypeOpen"/>
    </div>
</template>
<script>
import CommonTypesComponent from './../CommonTypesComponent/CommonTypesComponent.vue';

export default {
  name: 'StageFilterComponent',
  data() {
    return {
    }
  },
  components: {
    CommonTypesComponent,
  },
  props: {
    filterStageType: {
        type: Object,
        required: true,
    },
    updateParametersURL: {
        type: Function,
        required: true,
    },
    // checkActiveFilters: {
    //     type: Function,
    //     required: true,
    // },
    isFilterTypeOpen: {
        type: Boolean,
        required: true,
    },
  },
  methods: {
  },
};
</script>

<style scoped lang="scss">
    .filters_stage_type-body {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        width: 100%;
        margin-bottom: 4px;
        align-content: center;
        justify-content: flex-start;
        align-items: flex-start;
        .filters_stage_type-title {
            color: #757575;
            margin-left: 10px;
        }
    }
</style>
<template>
    <div class="popup_panel_wrapper">
        <div class="popup_panel_body">
            <div class="popup_panel_title">
                {{ popUpTitle }}
            </div>
            <div class="popup_panel_description" v-if="popUpDescription">
                {{ popUpDescription }}
            </div>
            <div class="popup_panel_btns">
                <div class="popup_panel_btn popup_panel_btn-cancel">
                    <button @click="functionClosePopUp">
                        {{ popUpButtonCancel }}
                    </button>
                </div>
                <div class="popup_panel_btn popup_panel_btn-clear">
                    <button @click="functionAction">
                        <img class="image_btn" src="@/images/icons/clear.svg" v-if="isIconView">
                        <div>
                            {{ popUpButtonAccept }}
                        </div>
                    </button>
                </div>
            </div>
            <div class="popup_panel_btn-close">
                <button @click="functionClosePopUp">
                    <img src="@/images/icons/delete.svg">
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PopUpPanelComponent",
    methods: {
    },
    props: {
        functionClosePopUp: {
            type: Function,
            required: true,
        },
        functionAction: {
            type: Function,
            required: true,
        },
        popUpTitle: {
            type: String,
            required: true,
        },
        popUpDescription: {
            type: String,
            required: false,
        },
        popUpButtonCancel: {
            type: String,
            required: true,
        },
        popUpButtonAccept: {
            type: String,
            required: true,
        },
        isIconView: {
            type: Boolean,
            required: true,
        }
    }
}
</script>

<style scoped lang="scss">
    .popup_panel_wrapper {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1000;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        background-color: rgba(17, 17, 17, 0.5);
        .popup_panel_body {
            position: relative;
            width: 500px;
            height: 200px;
            background-color: #FFFFFF;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            align-content: center;
            gap: 25px;
            color: #111111;
            .popup_panel_title {
                font-size: 20px;
                font-weight: 700;
            }
            .popup_panel_description {
                color: #757575;
                font-size: 16px;
                font-weight: 500;
            }
            .popup_panel_btns {
                display: flex;
                align-items: center;
                justify-content: center;
                align-content: center;
                gap: 8px;
                .popup_panel_btn {
                    width: 216px;
                    height: 40px;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    align-content: center;
                    button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        align-content: center;
                        gap: 10px;
                        font-size: 14px;
                        font-weight: 600;
                        .image_btn {
                            display: block;
                        }
                    }
                }
                .popup_panel_btn-cancel {
                    border: 2px solid #757575;
                    button {
                        width: 100%;
                        height: 100%;
                        color: #757575;
                    }
                    &:hover {
                        border: 2px solid #111111;
                        button {
                            color: #111111;
                        }
                    }
                }
                .popup_panel_btn-clear {
                    background-color: #FFCC29;
                    button {
                        width: 100%;
                        height: 100%;
                    }
                    &:hover {
                        border: 1px solid #757575;
                    }
                }
            }
            .popup_panel_btn-close {
                position: absolute;
                top: 10px;
                right: 10px;
                img {
                    width: 24px;
                    height: 24px;
                    filter: brightness(0) saturate(100%);
                }
            }
        }
    }
</style>
<template>
        <div class="signup_panel" v-if="!isSuccessRegistration">
          <div class="signup_panel_title">
            Create an account
          </div>
          <PopupNotSuccesEnter 
            :message="messageError"
            v-if="isBadRequest"/>
          <div class="signup_panel_form">
            <div class="singup_panel_elements_wrapper">
                <div class="singup_panel_elements_item">
                    <NameInputComponent 
                      :changeNameParameters="changeNameParameters"/>
                    <CompanyInputComponent
                      :changeCompanyParameters="changeCompanyParameters"/>
                    <EmailPanelComponent
                      :changeEmailParameters="changeEmailParameters"/>
                </div>
                <PasswordBlockComponent :isCorrectPasswords="isCorrectPasswords"/>
            </div>
            <div class="signup_panel_help">
                By clicking the “Sign up” button, you are creating an account and therefore agreeing to the <span>Terms of Use</span> and <span>Privacy Policy</span>
            </div>
            <ButtonAuthorization
              :nameButton="'Sign Up'"
              :clickFunction="registartionUser"/>
            <div class="signup_panel_auth">
                <span>Already have an account?</span> 
                <div class="auth_wrapper" @click="startAuth">
                    Log in
                </div>
            </div>
          </div>
        </div>
        <SuccesPopUpComponent :buttonFunction="startAuth" v-else/>
</template>

<script>
import { useRouter } from 'vue-router';
import constants from '@/constants/constants';
import EmailPanelComponent from '@/components/Others/InputPanels/EmailPanelComponent/EmailPanelComponent.vue';
import PopupNotSuccesEnter from '@/components/Others/InputPanels/PopupNotSuccesEnter/PopupNotSuccesEnter';
import ButtonAuthorization from '@/components/Others/ButtonAuthorization/ButtonAuthorization';
import PasswordBlockComponent from '../PasswordBlockComponent/PasswordBlockComponent';
import SuccesPopUpComponent from '../../SuccesPopUpComponent/SuccesPopUpComponent';
import CompanyInputComponent from '../CompanyInputComponent/CompanyInputComponent';
import NameInputComponent from '../NameInputComponent/NameInputComponent';

export default {
    name: 'SignupPanelComponent',
    components: {
        NameInputComponent,
        CompanyInputComponent,
        EmailPanelComponent,
        PasswordBlockComponent,
        ButtonAuthorization,
        PopupNotSuccesEnter,
        SuccesPopUpComponent,
    },
    data () {
        return {
            name: '',
            company: '',
            email: '',
            password: '',
            messageError: '',
            isViewPassword: false,
            isNotCorrectCompany: true,
            isNotCorrectEmail: true,
            isNotCorrectPass: true,
            isNotCorrectName: true,
            isBadRequest: false,
            isSuccessRegistration: false,
            router: useRouter(),
        }
    },
    methods: {
        changeNameParameters(nameText, isValid) {
            this.name = nameText;
            this.isNotCorrectName = isValid;
        },
        changeCompanyParameters(inputData, isValid) {
            this.company = inputData;
            this.isNotCorrectCompany = isValid;
        },
        changeEmailParameters(inputData, isValid) {
            this.email = inputData;
            this.isNotCorrectEmail = isValid;
        },
        isCorrectPasswords(inputData, isValid) {
            this.password = inputData;
            this.isNotCorrectPass = !isValid;
        },
        registartionUser() {
            if(!this.isCanLogIn()) return;
            const registerData = {
                name: this.name,
                email: this.email,
                company: this.company,
                password: this.password,
            }
            this.$store.dispatch('REGISTRATION_USER', registerData).then(res =>{
                if(res.status === constants.STATUS_CODE.BAD_LOG_IN || res.status === constants.STATUS_CODE.BAD_REQUEST) {
                    this.isBadRequest = true;
                    this.messageError = res.data.detail;
                }
                if(res.status === constants.STATUS_CODE.OK) {
                    this.isSuccessRegistration = true;
                }
            })
        },
        isCanLogIn() {
            const isCorrect = !this.isNotCorrectPass && !this.isNotCorrectEmail && !this.isNotCorrectCompany && !this.isNotCorrectName;
            // const isNotEmptyInput = !!this.email.length && !!this.password.length && !!this.company.length && !!this.name.length;
            return isCorrect;
        },
        startAuth() {
            this.$router.push(constants.PATH_NAME.AUTHORIZATION)
        },
    },
};
</script>
  
<style lang="scss">
    .signup_panel {
        position: relative;
        min-width: 400px;
        max-width: 750px;
        min-height: 430px;
        background-color: #FFFFFF;
        border-radius: 10px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        padding: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin: 30px;
        gap: 20px;
        .signup_panel_title {
            font-size: 20px;
            font-weight: 700;
            color: #111111;
        }
        .signup_panel_form {
            font-size: 15px;
            font-weight: 700;
            color: #111111;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .singup_panel_elements_wrapper {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr;
                align-items: flex-start;
                justify-content: center;
                gap: 20px;
                .singup_panel_elements_item {
                    width: 100%;
                }
            }
            .signup_panel_help {
                max-width: 440px;
                margin-bottom: 20px;
                font-size: 14px;
                font-weight: 500;
                color: #757575;
                span {
                    font-weight: 600;
                    color: #111111;
                    cursor: pointer;
                    &:hover {
                        color: #0085FF;
                    }
                }
            }
            .signup_panel_auth {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 4px;
                margin-top: 20px;
                margin-bottom: 10px;
                color: #111111;
                span {
                    font-weight: 500;
                    color: #757575;
                }
                .auth_wrapper {
                    cursor: pointer;
                    &:hover {
                        color: #0085FF;
                    }
                }
            }
        }
    }
    @media (max-width: 800px) {
        .signup_panel {
            .signup_panel_form {
                .singup_panel_elements_wrapper {
                    grid-template-columns: 1fr;
                    gap: 0;
                }
            }
        }
    }
</style>

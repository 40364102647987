<template>
    <div class="all_games_popup">
        <div class="games_search">
            <input type="text" placeholder="Name of game" v-model="nameGame" class="games_search_input"/>
        </div>
        <PopupNotFoundInfoComponent :nameElement="filterType.name" v-if="!allGamesView.length"/>
        <div class="all_games_component">
          <ul class="all_games_list" ref="list_games_elements">
            <GameSubtypeComponent v-for="filterSubtype in allGamesView"
                :filterSubtype="filterSubtype"
                :updateParametersURL="updateParametersURL"
                :parentType="filterType" />
          </ul>
        </div>
    </div>
</template>
<script>
import PopupNotFoundInfoComponent from '@/components/Others/PopupNotFoundInfoComponent/PopupNotFoundInfoComponent.vue';
import GameSubtypeComponent from './../GameSubtypeComponent/GameSubtypeComponent.vue';
import getCountColumns from '@/utils/getCountColumns/getCountColumns';

export default {
  name: 'GamessPopupComponent',
  data() {
    return {
      nameGame: '',
    }
  },
  components: {
    GameSubtypeComponent,
    PopupNotFoundInfoComponent,
  },
  props: {
    filterType: {
        type: Object,
        required: true,
    },
    allGamesView: {
        type: Array,
        required: true,
    },
    updateParametersURL: {
        type: Function,
        required: true,
    },
    searchGame: {
        type: Function,
        required: true,
    },
  },
  watch: {
    nameGame(searchWord) {
        this.searchGame(searchWord);
    },
    allGamesView(value) {
      this.$refs.list_games_elements.style.columnCount = getCountColumns(value.length);
    }
  },
  mounted() {
    this.$refs.list_games_elements.style.columnCount = getCountColumns(this.allGamesView.length);
  }
};
</script>
<style scoped lang="scss">
    .all_games_popup {
      list-style: none;
      padding: 20px 10px;
      margin-right: 10px;
      border-radius: 10px;
      background-color: #ffffff;
      width: auto;
      min-width: 340px;
      min-height: 192px;
      max-height: 500px;
      box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-content: flex-start;
      gap: 20px;
      transition: 1s;
      opacity: 1;
      animation: viewgames .3s ease;
      @keyframes viewgames {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 0.95;
        }
        100% {
          opacity: 1;
        }
      }
      .games_search {
        margin-left: 10px;
        display: flex;
        justify-content: flex-start;
        .games_search_input {
          width: 220px;
          height: 30px;
          background: #EBEFF5;
          border: 1px solid #EBEFF5;
          border-radius: 5px;
          font-size: 14px;
          font-weight: 500;
          outline: none;
          padding: 6px 10px;
        }
      }
      .games_info {
        width: 100%;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 6px;
        background-color: rgba(255, 205, 41, 0.2);
        padding: 18px 21px;
        border-radius: 5px;
        p {
            font-size: 14px;
        }
      }
      .all_games_component {
        max-width: 1020px;
        overflow-y: auto;
        .all_games_list {
          column-count: 3;
          column-gap: 5px;
          column-rule: solid 1px #EBEFF5;
          line-height: 1.429;
          .games_item {
            position: relative;
            padding-right: 0;
            padding-bottom: 6px;
            margin-left: 10px;
            align-items: flex-start;
          }
        }
      }
    }
    .hide-games {
      animation: hidegames .3s ease;
      @keyframes hidegames {
        0% {
          opacity: 1;
        }
        60% {
          opacity: 0;
        }
        100% {
          opacity: 0;
        }
      }
    }
</style>
<template>
    <section class="images_view_component" draggable="false">
      <div class="images_view_component_body"
        ref="data_view_component_body"
        v-if="dataImages.length !== 0">
        <ul class="images_view_component_list" 
          ref="data_component_list_body"
          v-on:scroll="scrollElements">
          <ImageComponent 
            v-for="(imageData, index) in dataImages"
            :imageData="imageData"
            :key="index"
            :index="index"
            :activeImage="activeImage" />
            <div class="load" v-if="loadingTime">Loading...</div>
        </ul>
        <div class="view_bottom_elements">
          <ButtonNextPageComponent
            :actionFunction="nextPage"
            :disabledState="isMaxPage"
            v-if="dataImages.length >= maxPageSize"/>
            <div class="view_page_counter">
              Page <span class="view_current_page">{{ getCurrentPage }}</span> of {{ getLastPage }}
            </div>
        </div>
      </div>
        <BadRequesComponent v-else-if="isBadRequest"/>
        <LoaderComponent v-else-if="loadingTime" />
        <WorkInProgress v-else/>
    </section>
</template>

<script>
import ImageComponent from './ImageComponent/ImageComponent.vue';
import LoaderComponent from '@/components/Others/LoaderComponent.vue';
import BadRequesComponent from '@/components/Others/BadRequesComponent.vue';
import WorkInProgress from '@/components/Others/WorkInProgress.vue';
import ButtonNextPageComponent from '@/components/Others/ButtonNextPageComponent/ButtonNextPageComponent.vue';
import constants from '@/constants/constants';

export default {
  name: 'ImagesViewComponent',
  components: {
    ImageComponent,
    ButtonNextPageComponent,
    LoaderComponent,
    BadRequesComponent,
    WorkInProgress,
  },
  data() {
      return {
          prevActiveImageIndex: null,
          maxPageSize: constants.SIZE_PAGE,
      }
  },
  props: {
    dataImages: {
        type: Array,
        required: true,
    },
    loadingTime: {
        type: Boolean,
        required: true,
    },
    isBadRequest: {
        type: Boolean,
        required: true,
    },
  },
  methods: {
    activeImage(element, index) {
      if(this.prevActiveImageIndex !== null && this.dataImages[this.prevActiveImageIndex]) {
        this.dataImages[this.prevActiveImageIndex].selected = false;
      }
      this.prevActiveImageIndex = index;
      element.selected = true;
      this.$store.commit('SET_ASIDE_IMAGE_DATA', element);
    },
    scrollElements(element) {
      const positionScroll = element.target.scrollTop;
      const endScrolling = element.target.scrollHeight - element.target.clientHeight;
      // console.log(positionScroll, endScrolling)
      if(positionScroll >= endScrolling) {
        this.nextPage()
      }
      
    },
    nextPage() {
      const element = this.$refs.data_component_list_body;
      const end = element.scrollHeight;
      element.scrollTo({
        top: end,
        left: 0,
        behavior: 'smooth'
      });

      this.$store.dispatch('DEBOUNCED_GET_DATA', true);
    },
  },
  computed: {
    isMaxPage() {
      return this.$store.getters.IS_MAX_PAGE;
    },
    getLastPage() {
      return this.$store.getters.LAST_PAGE;
    },
    getCurrentPage() {
      return this.$store.getters.CURRENT_PAGE;
    },
  }
};
</script>

<style scoped lang="scss">
    .images_view_component {
        flex-shrink: 1;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;
        flex-wrap: wrap;
        margin-top: 5px;
        height: 100%;
        .images_view_component_body {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 20px;
          width: 100%;
          height: 100%;
          max-height: calc(100vh - 250px);
          .images_view_component_list {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            align-content: flex-start;
            flex-wrap: wrap;
            gap: 10px;
            padding: 10px 10px 30px 20px;
            list-style: none;
            overflow-y: auto;
            overflow-x: hidden;
            .load {
              width: 100%;
            }
          }
          .view_bottom_elements {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 44px;
            padding: 14px 0;
            .view_page_counter {
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: center;
              right: 6px;
              font-size: 14px;
              .view_current_page {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #757575;
                border-radius: 10px;
                width: 40px;
                height: 40px;
                font-size: 14px;
                font-weight: 600;
                margin: 0 6px;
              }
            }
          }
        }
    }
</style>
<template>
    <button class="toggle_filter_panel">
        <img :src="require(`@/images/sprites/svg/${!filterPanel.isOpen ? 'settings2' : 'collapse'}.svg`)" alt="">
    </button>
</template>

<script>

export default {
  name: 'TogglePanelFilter',
  props: {
    filterPanel: {
        type: Object,
        required: true,
    }
  }
};
</script>

<style scoped lang="scss">
    .toggle_filter_panel {
      outline: none;
      min-width: 40px;
      width: 40px;
      height: 40px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      background: #EBEFF5;
      margin-right: 10px;
      cursor: pointer;
      img {
        width: 65%;
        height: 65%;
      }
      &:hover {
        img {
          filter: brightness(0) saturate(100%) invert(86%) sepia(35%) saturate(6545%) hue-rotate(187deg) brightness(100%) contrast(104%);
        }
      }
    }
</style>
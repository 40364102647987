<template>
    
    <li class="filter_item"  v-if="isCheckboxElement()">
        <CustomCheckboxComponent 
            :checkboxInfo="filterSubtype"
            :checkboxChangeFunction="applyFilter"
            :stateCheckbox="isCheckedFilter"/>
    </li>
    <li 
      :class="isCheckedFilter ? 'filter_item-tag active' : 'filter_item-tag'"
      :title="filterSubtype.name"
      @click="applyFilter"
      v-else>
        <span class="filter_tag_subtype" v-if="!isProportion">
            {{ filterSubtype.name }}
        </span>
        <span class="filters_tag_subtype-image" v-else>
            <PropotionImagesComponent 
                :subFilterName="filterSubtype.name"/>
        </span>
    </li>
</template>

<script>
import CustomCheckboxComponent from '@/components/Others/CustomCheckboxComponent/CustomCheckboxComponent.vue';
import PropotionImagesComponent from '@/components/Others/PropotionImagesComponent/PropotionImagesComponent.vue';
import constants from '@/constants/constants';
import createObjectForStore from '@/utils/createObjectForStore/createObjectForStore.js';

export default {
  name: 'FilterSubtypeComponent',
  data() {
    return {
        checkedFilter: false,
    }
  },
  components: {
    CustomCheckboxComponent,
    PropotionImagesComponent,
  },
  props: {
    filterSubtype: {
        type: Object,
        required: true,
    },
    filterTypeView: {
        type: String,
        required: true,
    },
    filterValue: {
        type: Object,
        required: true,
    },
    updateParametersURL: {
        type: Function,
        required: true,
    },
    isProportion: {
        type: Boolean,
        required: true,
    },
    // checkActiveFilters: {
    //     type: Function,
    //     required: true,
    // },
  },
  methods: {
    isCheckboxElement() {
        return this.filterTypeView === 'checkbox';
    },
    applyFilter() {
        this.checkedFilter = !this.checkedFilter;

        const filter = createObjectForStore(this.filterValue, this.filterSubtype);

        if(this.checkedFilter) {
            this.$store.commit('ADD_ACTIVE_FILTERS', filter);
            this.updateParametersURL(filter, constants.FILTERS, true)
        } else {
            this.$store.commit('REMOVE_ACTIVE_FILTERS', filter);
            this.updateParametersURL(filter, constants.FILTERS, false)
        }
        this.$store.dispatch('DEBOUNCED_GET_DATA', '');
    },
  },
  computed: {
    isCheckedFilter() {
        const filter = this.$store.getters.ACTIVE_FILTERS_BY_ID(this.filterSubtype.id);
        this.checkedFilter = !!filter;
        return !!filter;
    },
  },
};
</script>

<style lang="scss">
    .filter_tag_subtype,
    .filters_tag_subtype-image {
        font-weight: 600;
        font-size: 14px;
        background: #EBEFF5;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        transition: all .3s ease-out;
        cursor: pointer;
        &:hover {
            background: #FFCC29;
            img {
                filter: brightness(0) saturate(100%) invert(8%) sepia(5%) saturate(29%) hue-rotate(314deg) brightness(104%) contrast(104%);
            }
        }
    }
    .filter_tag_subtype {
        border-radius: 32px;
        padding: 6px 18px;
    }
    .filters_tag_subtype-image {
        width: 30px;
        height: 30px;
        border-radius: 4px;
    }
    .active {
        .filter_tag_subtype,
        .filters_tag_subtype-image {
            background: #FFCC29;
            img {
                filter: brightness(0) saturate(100%) invert(8%) sepia(5%) saturate(29%) hue-rotate(314deg) brightness(104%) contrast(104%);
            }
        }
    }
    .filter_item {
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        gap: 8px;
        height: 19px;
        width: 100%;
        cursor: pointer;
    }
</style>
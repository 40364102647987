<template>
  <div class="filters_panel_tags_open_list" v-if="isFilterTypeOpen">
    <div class="panel_tags_btn">
      <TagsGetButtonComponent :getAllDataTags="getAllDataTags" v-if="viewBtnAll"/>
    </div>
    <ul class="filters_panel_type_body-tags">
      <TagSubtypeComponent v-for="tag in filterType.tagsList"
        :filterSubtype="tag"
        :updateParametersURL="updateParametersURL"
        :parentType="filterType" />
      <div class="popup_container" ref="pop">
        <TagsPopupComponent v-if="viewAllTags"
          :filterType="filterType"
          :allTagsView="allTagsView"
          :updateParametersURL="updateParametersURL"
          :searchTag="searchTag"
        />
      </div>
    </ul>
  </div>
</template>
<script>
import TagSubtypeComponent from './TagSubtypeComponent/TagSubtypeComponent.vue';
import TagsGetButtonComponent from './TagsGetButtonComponent/TagsGetButtonComponent.vue';
import TagsPopupComponent from './TagsPopupComponent/TagsPopupComponent.vue';

export default {
  name: 'TagsComponent',
  data() {
    return {
      dataTags: [],
      viewAllTags: false,
      allTagsView: [],
      allTags: [],
      viewBtnAll: true,
      // hidePopUp: false,
    }
  },
  components: {
    TagSubtypeComponent,
    TagsGetButtonComponent,
    TagsPopupComponent,
  },
  props: {
    filterType: {
        type: Object,
        required: true,
    },
    isFilterTypeOpen: {
        type: Boolean,
        required: true,
    },
    updateParametersURL: {
        type: Function,
        required: true,
    },
    isOpenPopUp: {
        type: Object,
        required: true,
    },
  },
  methods: {
    // updateDefaultTags(game) {
    //   console.log(this.dataTags)
    //   const elem = this.dataTags.find(el => el.id === game.id)
    //   if(!elem) {
    //     this.dataTags.push(game);
    //   }
    //   console.log(this.dataTags)
    // },
    openPopupTags(event) {
        this.viewAllTags = !this.viewAllTags;
        const offsetY = window.innerHeight - event.clientY > 300 ? 34 : 150;
        if(this.viewAllTags) {
            this.$refs.pop.style.top = event.clientY - offsetY + 'px';
        }
    },
    eventClick(e) {
        const target = !e.target.closest('.all_tags_popup')
        if(target) {
            this.viewAllTags = false;
        }
    },
    getAllDataTags(e) {
      if(this.viewAllTags) {
        return;
      }
      this.isOpenPopUp.games = true;
      this.hidePopUp = false;
      this.$store.dispatch('GET_ALL_DATA_TAGS', '').then(() => {
        const arrAllTags = this.$store.getters.ALL_DATA_TAGS;
        // console.log(arrAllTags)
        this.allTagsView = [...arrAllTags];
        this.allTags = [...this.allTagsView];

        if(this.allTags.length) {
          this.openPopupTags(e);
        }
      });
    },
    searchTag(searchWord) {
      const screenSearchWord = searchWord.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
      if(!!searchWord) {
        this.allTagsView = this.allTags.filter(element => {
          const nameGame = new RegExp(screenSearchWord,"gi")
          return element.name.match(nameGame);
        })
      } else {
        this.allTagsView = this.allTags;
      }
    }
  },
  computed: {
    getDataTags() {
      // console.log(this.filterType)
      // return this.filterType
      this.dataTags = this.$store.getters.DATA_TAGS;
      // console.log(this.dataTags)
      // this.viewBtnAll = this.$store.getters.DATA_GAMES['game_name_number'] > DEFAULT_MIN_GAME_NUMBER;

      return this.dataTags;
    }
  },
  mounted() {
      // this.dataGames = this.filterType.game_name ? this.filterType.game_name : [];
  },
  watch: {
    viewAllTags(value) {
        if(value) {
            window.addEventListener('click', this.eventClick)
        } else {
            window.removeEventListener('click', this.eventClick)
        }
    }
  }
};
</script>
<style scoped lang="scss">
  .filters_panel_tags_open_list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    .panel_tags_btn {
      width: 100%;
    }
    .filters_panel_type_body-tags {
      display: flex;
      justify-content: flex-start;
      align-content: center;
      flex-wrap: wrap;
      list-style: none;
      gap: 4px;
      width: 100%;
      position: relative;
      overflow: visible;
      margin-left: 10px;
      .popup_container {
        position: fixed;
        left: 350px;
        z-index: 99;
      }
    }
  }
</style>
<template>
    <div class="authorization_panel_form_btn">
        <button class="form_btn" @click="clickFunction">
            <img src="@/images/icons/icon_login.svg" alt="icon_login" v-if="isLogInBtn()"/>
            {{ nameButton }}
        </button>
    </div>
</template>
<script>
import constants from '@/constants/constants';

export default {
    name: 'ButtonAuthorization',
    props: {
        nameButton: {
            type: String,
            required: true,
        },
        clickFunction: {
            type: Function,
            required: true,
        },
    },
    methods: {
        isLogInBtn() {
            return this.nameButton.toLowerCase() === constants.LOG_IN_NAME;
        }
    }
}
</script>
<style>
.authorization_panel_form_btn {
    margin: 10px 0;
    width: 100%;
    height: 40px;
    .form_btn {
    width: 100%;
    height: 100%;
    background-color: #FFCC29;
    border: 1px solid #FFCC29;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s ease-out;
    &:hover {
        border: 1px solid #757575;
        }
    }
}
</style>

const state = {
  dataCollect: [],
  isDragEvent: {isOn: false},
};

const getters = {
  DATA_COLLECT: state => {
    return state.dataCollect;
  },
  IS_DRAG_EVENT: state => {
    return state.isDragEvent;
  },
};

const mutations = {
  ADD_DATA: (state, payload) => {
    if(!state.dataCollect.find(element => element.id === payload.id)) {
      state.dataCollect.push(payload);
    };
  },
  CLEAR_DATA_COLLECT: (state, payload) => {
    state.dataCollect.splice(0, state.dataCollect.length);
  },
  REMOVE_ELEMENT_COLLECT: (state, index) => {
    state.dataCollect.splice(index,1);
  },
  TOGGLE_IS_DRAG_EVENT: (state, value) => {
    state.isDragEvent.isOn = value;
  }
  
};

const actions = {
  
};


export default {
  state,
  getters,
  mutations,
  actions,
}
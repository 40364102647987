<template>
    <li class="active_filters_item">
        <div class="active_filters_name">
            {{ activeFilter.activeElementName }}
            <PropotionImagesComponent 
                :subFilterName="activeFilter.activeElementName"
                v-if="isProportionFilter()"/>
        </div>
        <div class="active_filters_delete" @click="deleteActiveFilter(activeFilter)">
            <img src="@/images/icons/cross.svg" alt="Delete"/>
        </div>
    </li>
</template>

<script>
import PropotionImagesComponent from '@/components/Others/PropotionImagesComponent/PropotionImagesComponent.vue';
import constants from '@/constants/constants';

export default {
    name: 'ActiveFiltersItem',
    data () {
        return {
        }
    },
    components: {
        PropotionImagesComponent,
    },
    props: {
        activeFilter: {
            type: Object,
            required: true,
        },
        deleteActiveFilter: {
            type: Function,
            required: true,
        }
    },
    methods: {
        isProportionFilter() {
            return this.activeFilter.name.toLowerCase() === constants.FILTER_PROPORTION;
        }
    }
}
</script>

<style scoped lang="scss">
    .active_filters_item {
        border-radius: 20px;
        font-weight: 500;
        font-size: 16px;
        background: #EBEFF5;
        border: 1px solid #EBEFF5;
        min-height: 30px;
        padding: 0px 12px 0px 13px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        transition: all .3s ease-out;
        &:hover {
            border: 1px solid #757575;
        }
        .active_filters_name {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
        }
        .active_filters_delete {
            cursor: pointer;
            img {
                opacity: 0.5;
                display: block;
                width: 12px;
                height: 12px;
            }
            &:hover img{
                opacity: 1;
                filter: brightness(0) saturate(100%) invert(0%) sepia(14%) saturate(269%) hue-rotate(169deg) brightness(97%) contrast(84%);
            }
        }
    }
</style>
<template>
    <li class="games_item">
        <CustomCheckboxComponent 
            :checkboxInfo="filterSubtype"
            :checkboxChangeFunction="applyFilter"
            :stateCheckbox="isCheckedGame"/>
    </li>
</template>

<script>
import CustomCheckboxComponent from '@/components/Others/CustomCheckboxComponent/CustomCheckboxComponent.vue';
import constants from '@/constants/constants';
import createObjectForStore from '@/utils/createObjectForStore/createObjectForStore.js';

export default {
  name: 'GameSubtypeComponent',
  data() {
    return {
        checkedFilter: false,
    }
  },
  components: {
    CustomCheckboxComponent,
  },
  props: {
    filterSubtype: {
        type: Object,
        required: true,
    },
    updateParametersURL: {
        type: Function,
        required: true,
    },
    parentType: {
        type: Object,
        required: true,
    },
  },
  methods: {
    applyFilter() {
        this.checkedFilter = !this.checkedFilter;
        const filter = createObjectForStore(this.parentType, this.filterSubtype);

        if(this.checkedFilter) {
            this.$store.commit('ADD_ACTIVE_GAMES', filter);
            this.$store.commit('UPDATE_DEFAULT_DATA_GAMES', this.filterSubtype);
            this.updateParametersURL(filter, constants.GAMES, true)
        } else {
            this.$store.commit('REMOVE_ACTIVE_GAMES', filter);
            this.updateParametersURL(filter, constants.GAMES, false)
        }

        this.$store.dispatch('DEBOUNCED_GET_DATA', '');
    }
  },
  computed: {
    isCheckedGame() {
        const game = this.$store.getters.ACTIVE_GAMES_BY_ID(this.filterSubtype.id);
        this.checkedFilter = !!game;
        return !!game;
    },
  }
};
</script>

<style scoped lang="scss">
    .games_item {
        display: flex;
        justify-content: space-between;
        align-content: center;
        align-items: center;
        gap: 8px;
        cursor: pointer;
    }
</style>
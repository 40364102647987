<template>
    <PopUpPanelComponent
        :functionClosePopUp="closePoUp"
        :functionAction="clearAllCollect"
        :popUpTitle="'Clear your collection?'"
        :popUpDescription="'This will remove all images from the collection'"
        :popUpButtonCancel="'Cancel'"
        :popUpButtonAccept="'Yes, clear'"
        :isIconView="true"
    />
</template>

<script>
import PopUpPanelComponent from './../PopUpPanelComponent/PopUpPanelComponent.vue';

export default {
    name: "ClearAllPopup",
    components: {
        PopUpPanelComponent,
    },
    methods: {
    },
    props: {
        closePoUp: {
            type: Function,
            required: true,
        },
        clearAllCollect: {
            type: Function,
            required: true,
        }
    }
}
</script>
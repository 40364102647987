<template>
        <li  :class="isCheckedFilter() ? 'selected_item__tag active_tag' : 'selected_item__tag'" @click="applyFilter">
            {{ tagElement.name }}
            <PropotionImagesComponent
                :subFilterName="tagElement.name"
                v-if="isProportionFilter"/>
        </li>
</template>

<script>
import PropotionImagesComponent from '@/components/Others/PropotionImagesComponent/PropotionImagesComponent.vue';
import constants from '@/constants/constants';
import createObjectForStore from '@/utils/createObjectForStore/createObjectForStore.js'
import updateCurrentQuery from '@/utils/updateCurrentQuery/updateCurrentQuery';

export default {
    name: "SelectedItemTag",
    data() {
        return {
            checkedFilter: false,
        }
    },
    components: {
        PropotionImagesComponent,
    },
    props: {
        tagElement: {
            type: Object,
            required: true,
        }
    },
    methods: {
        updateParametersURL(element, type, append, childType = undefined) {
            const currentQuery = { ...this.$route.query };
            let activeGames = null;
            if(childType) {
                activeGames = this.$store.getters.ACTIVE_GAMES;
            }

            this.$router.push({query: updateCurrentQuery(currentQuery, element, type, append, childType, activeGames)});
        },
        applyFilterProvider(checked) {
            const filter = createObjectForStore({name: constants.PROVIDERS}, this.tagElement);

            if(checked) {
                this.$store.commit('ADD_ACTIVE_PROVIDERS', filter);
                this.$store.commit('UPDATE_DEFAULT_DATA_PROVIDER', this.tagElement);
            } else {
                this.$store.commit('REMOVE_ACTIVE_PROVIDERS', filter);
            }
            
            this.$store.commit('UPDATE_ACTIVE_GAMES_BY_PROVIDER', this.$store.getters.ACTIVE_PROVIDERS);
            this.updateParametersURL(filter, constants.PROVIDERS, checked, constants.GAMES);
            this.$store.dispatch('GET_DATA_GAMES', '').then(() => {
                this.$store.commit('CHECK_DEFAULT_DATA_GAMES', '');
            });
        },
        applyFilterGame(checked) {
            const filter = createObjectForStore({name: constants.GAMES}, this.tagElement);
            
            if(checked) {
                this.$store.commit('ADD_ACTIVE_GAMES', filter);
                this.$store.commit('UPDATE_DEFAULT_DATA_GAMES', this.tagElement);
                this.updateParametersURL(filter, constants.GAMES, true)
            } else {
                this.$store.commit('REMOVE_ACTIVE_GAMES', filter);
                this.updateParametersURL(filter, constants.GAMES, false)
            }
        },
        applyFilterTag(checked) {
            const filter = createObjectForStore({name: constants.TAGS}, this.tagElement);
            
            if(checked) {
                this.$store.commit('ADD_ACTIVE_TAG', filter);
                this.$store.commit('UPDATE_DEFAULT_DATA_TAG', this.tagElement);
                this.updateParametersURL(filter, constants.TAGS, true)
            } else {
                this.$store.commit('REMOVE_ACTIVE_TAG', filter);
                this.updateParametersURL(filter, constants.TAGS, false)
            }
        },
        applyFilter() {
            this.checkedFilter = !this.checkedFilter;
            if(this.tagElement.provider) {
                this.applyFilterProvider(this.checkedFilter);
            } else if(this.tagElement.type === constants.GAMES) {
                this.applyFilterGame(this.checkedFilter);
            } else if(this.tagElement.type === constants.TAG_ELEMENT) {
                this.applyFilterTag(this.checkedFilter);
            } else {
                const parentFilter = this.$store.getters.PARENT_FILTERS_BY_ID(this.tagElement.filter_id);
                const filter = createObjectForStore(parentFilter, this.tagElement);
                if(this.checkedFilter) {
                    this.$store.commit('ADD_ACTIVE_FILTERS', filter);
                    this.updateParametersURL(filter, constants.FILTERS, true)
                } else {
                    this.$store.commit('REMOVE_ACTIVE_FILTERS', filter);
                    this.updateParametersURL(filter, constants.FILTERS, false)
                }
            }
            this.$store.dispatch('DEBOUNCED_GET_DATA', '');
        },
        isCheckedFilter() {
            let isActive = false;
            if(this.tagElement.provider) {
                isActive = this.$store.getters.ACTIVE_PROVIDERS_BY_ID(this.tagElement.id);
            } else if(this.tagElement.type === constants.GAMES) {
                isActive = this.$store.getters.ACTIVE_GAMES_BY_ID(this.tagElement.id);
            } else if(this.tagElement.type === constants.TAG_ELEMENT) {
                isActive = this.$store.getters.ACTIVE_TAG_BY_ID(this.tagElement.id);
            } else {
                isActive = this.$store.getters.ACTIVE_FILTERS_BY_ID(this.tagElement.id);
            }
            this.checkedFilter = !!isActive;
            return !!isActive;
        },
    },
    computed: {
        isProportionFilter() {
            if(!this.tagElement.provider && !this.tagElement.type) {
                const parentFilter = this.$store.getters.PARENT_FILTERS_BY_ID(this.tagElement.filter_id);
                return parentFilter?.name.toLowerCase() === constants.FILTER_PROPORTION;
            }
        }
    }
}
</script>

<style lang="scss">
    .selected_item__tag {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            gap: 4px;
            background-color: #fff;
            border-radius: 20px;
            padding: 8px 14px;
            list-style: none;
            margin: 0;
            box-sizing: border-box;
            img {
                height: 16px;
            }
            cursor: pointer;
            &:hover {
                background-color: #FFCC29;
                img {
                    filter: brightness(0) saturate(100%) invert(8%) sepia(5%) saturate(29%) hue-rotate(314deg) brightness(104%) contrast(104%);
                }
            }
    }
    .active_tag {
        background-color: #FFCC29;
        img {
            filter: brightness(0) saturate(100%) invert(8%) sepia(5%) saturate(29%) hue-rotate(314deg) brightness(104%) contrast(104%);
        }
    }
</style>
<template>
    <img src="@/images/icons/icon_proportion_square.svg"  alt="square" v-if="isSquare()">
    <img src="@/images/icons/icon_proportion_horizontal.svg"  alt="horizontal" v-if="isHorizont()">
    <img src="@/images/icons/icon_proportion_horizontal.svg" class="vertical_image" alt="vertical" v-if="isVertical()">
</template>

<script>
import constants from '@/constants/constants';

export default {
    name: 'PropotionImagesComponent',
    data() {
        return {
        }
    },
    props: {
        subFilterName: {
            type: String,
            required: true,
        }
    },
    methods: {
        isSquare() {
            return this.subFilterName.toLowerCase() === constants.PROPORTION_SQUARE;
        },
        isHorizont() {
            return this.subFilterName.toLowerCase() === constants.PROPORTION_HORIZONTAL;
        },
        isVertical() {
            return this.subFilterName.toLowerCase() === constants.PROPORTION_VERTICAL;
        },
    },
};
</script>

<style scoped lang="scss">
    .vertical_image {
        transform: rotate(90deg);
    }
</style>


<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App',
};
</script>

<style lang="scss">
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
</style>

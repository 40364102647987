<template>
    <ul class="filters_panel_type_body-provider" v-if="isFilterTypeOpen && getListProviders.length">
      <ProviderSubtypeComponent v-for="filterSubtype in arrOnlyProviders"
        :filterSubtype="filterSubtype"
        :updateParametersURL="updateParametersURL"
        :parentType="filterType" />
      <ProvidersGetButtonComponent :getAllDataProviders="getAllDataProviders"/>
      <div class="popup_container" ref="pop">
        <ProvidersPopupComponent v-if="viewAllProviders"
          :filterType="filterType"
          :allProvidersView="allProvidersView"
          :updateParametersURL="updateParametersURL"
          :searchProvider="searchProvider"
        />
      </div>
    </ul>
</template>
<script>
import constants from '@/constants/constants';
import ProviderSubtypeComponent from './../ProviderSubtypeComponent/ProviderSubtypeComponent.vue';
import ProvidersGetButtonComponent from './../ProvidersGetButtonComponent/ProvidersGetButtonComponent.vue';
import ProvidersPopupComponent from './../ProvidersPopupComponent/ProvidersPopupComponent.vue';

export default {
  name: 'ProviderFilterComponent',
  data() {
    return {
      arrOnlyProviders: [],
      viewAllProviders: false,
      allProvidersView: [],
      allProviders: [],
    }
  },
  components: {
    ProviderSubtypeComponent,
    ProvidersGetButtonComponent,
    ProvidersPopupComponent,
  },
  props: {
    filterType: {
        type: Object,
        required: true,
    },
    isFilterTypeOpen: {
        type: Boolean,
        required: true,
    },
    updateParametersURL: {
        type: Function,
        required: true,
    },
    isOpenPopUp: {
        type: Object,
        required: true,
    },
  },
  methods: {
    openPopupProvider(event) {
        this.viewAllProviders = !this.viewAllProviders;
        const offsetY = window.innerHeight - event.clientY > constants.POPUP_DISTANCE_PROVIDER.MAX_SCREEN_DISTANCE ? constants.POPUP_DISTANCE_PROVIDER.DISTANCE_UP : constants.POPUP_DISTANCE_PROVIDER.DISTANCE_DOWN;
        if(this.viewAllProviders) {
          this.$refs.pop.style.top = event.clientY - offsetY + 'px';
        } 
    },
    eventClick(e) {
        const target = !e.target.closest('.all_provider_popup')
        if(target) {
            this.viewAllProviders = false;
        }
    },
    getAllDataProviders(e) {
      if(this.viewAllProviders) {
        return;
      }
      this.isOpenPopUp.providers = true;
      this.$store.dispatch('GET_ALL_DATA_PROVIDERS', '').then(() => {
        const arrAllProviders = this.$store.getters.ALL_DATA_PROVIDERS;
        this.allProvidersView = [...arrAllProviders];
        this.allProviders = [...this.allProvidersView];
        
        if(this.allProviders.length) {
          this.openPopupProvider(e);
        }
      });
    },
    searchProvider(searchWord) {
      const screenSearchWord = searchWord.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
      if(!!searchWord) {
        this.allProvidersView = this.allProviders.filter(element => {
          const nameProvider = new RegExp(screenSearchWord,"gi")
          return element.name.match(nameProvider);
        })
      } else {
        this.allProvidersView = this.allProviders;
      }
    }
  },
  mounted() {
      this.arrOnlyProviders = this.filterType.provider ? this.filterType.provider : [];
  },
  computed: {
    getListProviders() {
      this.arrOnlyProviders = this.filterType.provider;
      return this.arrOnlyProviders;
    },
  },
  watch: {
      viewAllProviders(value) {
          if(value) {
              window.addEventListener('click', this.eventClick)
          } else {
              window.removeEventListener('click', this.eventClick)
          }
      }
  }
};
</script>
<style scoped lang="scss">
  .filters_panel_type_body-provider {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    row-gap: 12px;
    width: 100%;
    position: relative;
    overflow: visible;
    margin-left: 10px;
    .popup_container {
      position: fixed;
      left: 350px;
      z-index: 99;
    }
  }
</style>